
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Home, LotDetails, SearchAuto, SignIn, AddNews, PageAddLot, VinDecodingDetails, VinDecodingPage, BlogPage, NotFoundPage, NewsDetails, TermsOfUsePage,PrivacyPolicy, PublicAgreement } from './components';
function App() {
  return (
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/search-auto" element={<SearchAuto />} />
            <Route path="/news" element={<BlogPage />} />
            <Route path="/news/:title" element={<NewsDetails />} />
            <Route path="/lots/:vin" element={<LotDetails />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/add-lot" element={<PageAddLot />} />
            <Route path="/terms-of-use" element={<TermsOfUsePage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/public-agreement" element={<PublicAgreement />} />
            <Route path="/addnews" element={<AddNews />} />
            <Route path="/vin-decoding" element={<VinDecodingPage />} />
            <Route path="/vin-decoding/:vin" element={<VinDecodingDetails/>} />
            <Route path="/vin-decoding/:make/:model/:vin" element={<VinDecodingDetails />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
        <ToastContainer />
      </Router>
  );
}

export default App;
