import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

function AddNewsForm() {
    const [title, setTitle] = useState('');
    const [shortContent, setShortContent] = useState('');
    const [content, setContent] = useState('');
    const [category, setCategory] = useState('');
    const [images, setImages] = useState([]);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const publishDate = new Date();
            const formattedPublishDate = publishDate.toISOString();

            const formData = new FormData();
            formData.append('title', title);
            formData.append('short_content', shortContent);
            formData.append('content', content);
            formData.append('category', category);
            formData.append('author', 'tester');
            formData.append('publish_date', formattedPublishDate);
            images.forEach((image) => {
                formData.append('images', image);
            });

            const response = await fetch('https://dev.copia.vin/addnews', {
                method: 'POST',
                body: formData
            });
            if (response.ok) {
                navigate('/');
            } else {
                console.error('Failed to add news');
            }
        } catch (error) {
            console.error('Failed to add news:', error);
        }
    };

    const onDrop = (acceptedFiles) => {
        setImages([...images, ...acceptedFiles]);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <form onSubmit={handleSubmit}>
        <div class="py-5 bg-light m-3 rounded-4">
            <div class="container py-4">
                <div class="row justify-content-center">
                    <div class="col-sm-10 col-md-10 col-lg-8">

                        <div class="section-header text-center mb-5 aos-init aos-animate" data-aos="fade-down">
                            <div class="d-inline-block font-caveat fs-1 fw-medium section-header__subtitle text-capitalize text-primary">News</div>
                            <h2 class="display-5 fw-semibold mb-3 section-header__title text-capitalize">Add News</h2>
                            <div class="sub-title fs-16">Discover exciting categories. <span class="text-primary fw-semibold">Find what you’re looking for.</span></div>

                        </div>
                    </div>
                </div>
                <div class="row justify-content-center g-4">
                    <div class="col-xl-10 col-xxl-9">
                        <div class="border-0 card listing-form mb-4 p-4 rounded-4 shadow-sm">
                            <div class="align-items-sm-center bg-light d-sm-flex mb-4 p-4 rounded-3">
                                <div class="d-flex flex-shrink-0 gap-1">
                                    <span class="fw-semibold text-dark">01/</span>
                                    <div class="align-items-center bg-primary card-icon d-flex justify-content-center mb-2 rounded-circle text-white">
                                        <i class="fa-solid fa-user fs-5"></i>
                                    </div>
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <h4 class="fw-semibold font-caveat fs-2">Basic Informations</h4>
                                    <p class="mb-0 fs-15">There are many variations of passages of Lorem Ipsum<br class="d-none d-lg-block"/> available, but the majority have</p>
                                </div>
                            </div>
                            <div class="row g-4">
                                <div class="col-sm-6">
                                    <div class="">
                                        <label class="required fw-medium mb-2">Title</label>
                                        <input type="text" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} class="form-control" required=""/>
                                    </div>

                                </div>
                                <div class="col-sm-6">
                                    <div class="">
                                        <label className="required fw-medium mb-2">Category</label>

                                        <select className="form-select" defaultValue={'Automotive'} value={category}
                                                onChange={(e) => setCategory(e.target.value)}>
                                            <option value="">Select Category</option>
                                            <option value="Automotive">Automotive</option>
                                            <option value="News and Trends">News and Trends</option>
                                            <option value="Export and Delivery">Export and Delivery</option>
                                            <option value="Legal Aspects and Procedures">Legal Aspects and Procedures
                                            </option>
                                            <option value="Technical Review">Technical Review</option>
                                            <option value="Buying Tips">Buying Tips</option>
                                            <option value="Repair and Restoration">Repair and Restoration</option>
                                        </select>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="border-0 card listing-form mb-4 p-4 rounded-4 shadow-sm">
                            <div class="align-items-sm-center bg-light d-sm-flex mb-4 p-4 rounded-3">
                                <div class="d-flex flex-shrink-0 gap-1">
                                    <span class="fw-semibold text-dark">02/</span>
                                    <div class="align-items-center bg-primary card-icon d-flex justify-content-center mb-2 rounded-circle text-white">
                                        <i class="fa-solid fa-image fs-5"></i>
                                    </div>
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <h4 class="fw-semibold font-caveat fs-2">Poster News</h4>
                                    <p class="mb-0 fs-15">There are many variations of passages of Lorem Ipsum<br class="d-none d-lg-block"/> available, but the majority have</p>
                                </div>
                            </div>
                            <div class="">
                                <div className={'ff_fileupload_wrap'}{...getRootProps()} style={dropzoneStyles}>
                                    <input  {...getInputProps()} />
                                    {isDragActive ? (
                                        <p>Drop the files here ...</p>
                                    ) : (
                                        <div className="ff_fileupload_wrap">

                                            <p>Drag 'n' drop some images here, or click to select images</p>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="100px"
                                                height="100px"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                            >
                                                <path
                                                    d="M12 19V12M12 12L9.75 14.3333M12 12L14.25 14.3333M6.6 17.8333C4.61178 17.8333 3 16.1917 3 14.1667C3 12.498 4.09438 11.0897 5.59198 10.6457C5.65562 10.6268 5.7 10.5675 5.7 10.5C5.7 7.46243 8.11766 5 11.1 5C14.0823 5 16.5 7.46243 16.5 10.5C16.5 10.5582 16.5536 10.6014 16.6094 10.5887C16.8638 10.5306 17.1284 10.5 17.4 10.5C19.3882 10.5 21 12.1416 21 14.1667C21 16.1917 19.3882 17.8333 17.4 17.8333"
                                                    stroke="#f84525"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <div className="form-text">Recommended to 16:9 (png, jpg, jpeg).</div>
                                        </div>

                                    )}
                                </div>

                                <div>
                                    {images.map((image, index) => (
                                        <div key={index}>
                                            <table className="ff_fileupload_uploads">
                                                <tbody>
                                                <tr className="">
                                                    <td className="ff_fileupload_preview">
                                                        <button className="ff_fileupload_preview_image ff_fileupload_preview_image_has_preview" type="button" aria-label="Preview" style={{backgroundImage: `url(${URL.createObjectURL(image)})`}}>
                                                            <span className="ff_fileupload_preview_text"></span>
                                                        </button>
                                                        <div className="ff_fileupload_actions_mobile">
                                                            <button className="ff_fileupload_remove_file" type="button" aria-label="Remove from list"></button>
                                                        </div>
                                                    </td>

                                                    <td className="ff_fileupload_actions">
                                                        <button className="ff_fileupload_remove_file" type="button"
                                                                aria-label="Remove from list">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="35px"
                                                                 height="35px" viewBox="0 0 24 24" fill="none">
                                                                <path d="M20.5001 6H3.5" stroke="#f84525"
                                                                      stroke-width="1.5" stroke-linecap="round"/>
                                                                <path
                                                                    d="M18.8332 8.5L18.3732 15.3991C18.1962 18.054 18.1077 19.3815 17.2427 20.1907C16.3777 21 15.0473 21 12.3865 21H11.6132C8.95235 21 7.62195 21 6.75694 20.1907C5.89194 19.3815 5.80344 18.054 5.62644 15.3991L5.1665 8.5"
                                                                    stroke="#f84525" stroke-width="1.5"
                                                                    stroke-linecap="round"/>
                                                                <path d="M9.5 11L10 16" stroke="#f84525"
                                                                      stroke-width="1.5" stroke-linecap="round"/>
                                                                <path d="M14.5 11L14 16" stroke="#f84525"
                                                                      stroke-width="1.5" stroke-linecap="round"/>
                                                                <path
                                                                    d="M6.5 6C6.55588 6 6.58382 6 6.60915 5.99936C7.43259 5.97849 8.15902 5.45491 8.43922 4.68032C8.44784 4.65649 8.45667 4.62999 8.47434 4.57697L8.57143 4.28571C8.65431 4.03708 8.69575 3.91276 8.75071 3.8072C8.97001 3.38607 9.37574 3.09364 9.84461 3.01877C9.96213 3 10.0932 3 10.3553 3H13.6447C13.9068 3 14.0379 3 14.1554 3.01877C14.6243 3.09364 15.03 3.38607 15.2493 3.8072C15.3043 3.91276 15.3457 4.03708 15.4286 4.28571L15.5257 4.57697C15.5433 4.62992 15.5522 4.65651 15.5608 4.68032C15.841 5.45491 16.5674 5.97849 17.3909 5.99936C17.4162 6 17.4441 6 17.5 6"
                                                                    stroke="#f84525" stroke-width="1.5"/>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    ))}

                                </div>




                            </div>

                        </div>
                        <div class="border-0 card listing-form mb-4 p-4 rounded-4 shadow-sm">
                            <div class="align-items-sm-center bg-light d-sm-flex mb-4 p-4 rounded-3">
                                <div class="d-flex flex-shrink-0 gap-1">
                                    <span class="fw-semibold text-dark">03/</span>
                                    <div
                                        class="align-items-center bg-primary card-icon d-flex justify-content-center mb-2 rounded-circle text-white">
                                        <i class="fa-solid fa-circle-info fs-5"></i>
                                    </div>
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <h4 class="fw-semibold font-caveat fs-2">Details</h4>
                                    <p class="mb-0 fs-15">There are many variations of passages of Lorem Ipsum<br class="d-none d-lg-block"/> available, but the majority have</p>
                                </div>
                            </div>
                            <div class="row g-4">
                                <div class="col-sm-12">
                                    <div class="">
                                        <label className="required fw-medium mb-2">Short Description</label>
                                        <textarea className="form-control" rows="2"
                                                  placeholder="Please enter up to 100 characters."  value={shortContent}
                                                  onChange={(e) => setShortContent(e.target.value)}></textarea>

                                        <label className="required fw-medium mb-2">Description</label>
                                        <textarea className="form-control" rows="7"
                                                  placeholder="Please enter up to 4000 characters." value={content}
                                                  onChange={(e) => setContent(e.target.value)}></textarea>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="text-center mt-5">
                            <button type="submit" class="btn btn-primary btn-lg d-inline-flex hstack gap-2">
                            <span>Submit for approval</span>
                                <span class="vr"></span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px"
                                     viewBox="0 0 24 24" fill="none">
                                    <path
                                        d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z"
                                        fill="#ffffff"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>




            </form>

    );
}


const dropzoneStyles = {
    border: '2px dashed rgb(248 69 37)',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    marginTop: '20px'
};



export default AddNewsForm;
