import React from 'react';
import '../styles/BlogPage.css';

const NewsSingleContent = (data) => {
    return (
        <div className="py-5">
            <div className="container py-4">
                <div className="row justify-content-center">
                    <div className="col-xl-10 col-xxl-8">
                        <article className="post single-post">
                            <div className="entry-content cs-content">

                                <h1>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't
                                    anything.</h1>
                                <p>How it is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of
                                    using Lorem Ipsum is that it has a more-or-less normal distribution
                                    of letters, as opposed to using 'Content here, content here', making it look like
                                    readable English.</p>
                                <h2>Duis feugiat velit eu mauris dapibus mattis.</h2>
                                <p>{data.content}</p>



                            </div>

                            <div className="d-flex flex-wrap gap-3 my-4 post-tags tagcloud">

                            </div>

                            <div className="align-items-center border-bottom border-top d-flex justify-content-between py-4">
                                <div className="blog_time_read d-none d-sm-block fs-12 l-spacing-1 text-muted text-uppercase"> Reading time: 2 min </div>

                                <div className="align-items-center blog-social d-flex fs-12 l-spacing-1 text-uppercase"> Share:

                                </div>

                            </div>

                            <div className="align-items-center border justify-content-between mt-5 p-4 rounded">
                                <div className="row g-0">
                                    <div className="col-sm-6 ps-0 py-3 slider-blog-arrow">

                                    </div>
                                    <div className="col-sm-6 pe-0 py-3 slider-blog-arrow">

                                </div>
                            </div>
                    </div>

                </article>
            </div>
        </div>
</div>
</div>

)
    ;
};

export default NewsSingleContent;
