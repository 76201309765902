import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../styles/BlogPageBlock.css';

const BlogPageBlock = () => {
    const host = 'https://dev.copia.vin';
    const [newsData, setNewsData] = useState([]);

    useEffect(() => {
        fetchNews();
    }, []);

    const handleDelete = async (id) => {
            try {
                const response = await fetch(`https://dev.copia.vin/news/${id}`, {
                    method: 'DELETE'
                });
                if (response.ok) {
                    // Показываем уведомление об успешном удалении
                    toast.success('The news was successfully deleted', {
                        position: 'top-right',
                        autoClose: 3000 // Закрыть через 3 секунды
                    });
                    // Дополнительно, можно обновить список новостей после удаления
                } else {
                    console.error('Error deleting news:', response.statusText);
                }
            } catch (error) {
                console.error('Error deleting news:', error);
            }
        };
    const fetchNews = async () => {
        try {
            const response = await fetch('https://dev.copia.vin/news'); // Замените на соответствующий URL для вашего API
            const data = await response.json();
            setNewsData(data);
        } catch (error) {
            console.error('Error fetching news:', error);
        }
    };

    return (
        <div className="py-5" style={{ transform: 'none' }}>
            <div className="container py-4" style={{ transform: 'none' }}>
                <div className="row" style={{transform: 'none'}}>
                    <div className="col-lg-8 pe-lg-4 pe-xxl-5 content">
                        <div className="theiaStickySidebar">
                            <div className="blog-grid row">
                                {newsData.map((newsItem) => (
                                    <div key={newsItem.id} className="col-12 col-sm-12 col-lg-6 col-xxl-6">
                                        <article className="bloditem bg-light border-0 card overflow-hidden"
                                                 data-macy-complete="1">
                                            <div className="position-relative overflow-hidden">
                                                <Link to={`/news/${newsItem.title.replace(/\s+/g, '-')}`}
                                                      className="h-100 position-absolute start-0 top-0 w-100 z-1"
                                                      aria-label="Read more"></Link>

                                                <img
                                                    src={`${host}/${newsItem.images}`}
                                                    className="card-img-top image-zoom-hover" alt="img"/>
                                            </div>
                                            <div className="card-body">
                                                <div className="hstack gap-3 mb-3">
                                                    <span className="fs-sm small text-muted">date</span>
                                                    <span className="opacity-25">|</span>
                                                    <Link to={'/'} className="badge border fw-semibold text-primary bg-white"
                                                       >{newsItem.category}</Link>
                                                </div>
                                                <h3 className="h5 fw-semibold mb-0 post-title overflow-hidden">
                                                    <Link
                                                        to={`/news/${newsItem.title.replace(/\s+/g, '-')}`}>{newsItem.title}</Link>
                                                </h3>
                                                <p className="mt-3 mb-0">{newsItem.short_content}</p>
                                            </div>
                                            <div className="card-footer py-3">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-grow-1 ms-3">
                                                        <button onClick={() => handleDelete(newsItem.id)}>Delite news</button>
                                                        <Link to={`/news/${newsItem.title.replace(/\s+/g, '-')}`}
                                                              className="d-block"><span
                                                            className="fw-medium">Read more</span></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 ps-lg-4 ps-xxl-5 sidebar">


                        <div className="theiaStickySidebar">
                            <div className="mb-4 aos-init aos-animate" data-aos="fade">
                                <h5 className="fw-semibold mb-3 text-capitalize fs-23">Search <span
                                    className="font-caveat text-primary">Blog</span></h5>
                                <div className="input-group flex-nowrap">
                                    <span className="input-group-text" id="addon-wrapping"><svg
                                        xmlns="http://www.w3.org/2000/svg" width="20px" height="20px"
                                        viewBox="0 0 24 24" fill="currentColor" className="bi bi-plus-circle"><path
                                        fillRule="evenodd" clipRule="evenodd"
                                        d="M11.5 2.75C6.66751 2.75 2.75 6.66751 2.75 11.5C2.75 16.3325 6.66751 20.25 11.5 20.25C16.3325 20.25 20.25 16.3325 20.25 11.5C20.25 6.66751 16.3325 2.75 11.5 2.75ZM1.25 11.5C1.25 5.83908 5.83908 1.25 11.5 1.25C17.1609 1.25 21.75 5.83908 21.75 11.5C21.75 14.0605 20.8111 16.4017 19.2589 18.1982L22.5303 21.4697C22.8232 21.7626 22.8232 22.2374 22.5303 22.5303C22.2374 22.8232 21.7626 22.8232 21.4697 22.5303L18.1982 19.2589C16.4017 20.8111 14.0605 21.75 11.5 21.75C5.83908 21.75 1.25 17.1609 1.25 11.5Z"
                                        fill="#000000"></path></svg></span>
                                    <input type="text" className="form-control" placeholder="Type and hit enter"/>
                                </div>
                            </div>

                            <div className="mb-4 aos-init" data-aos="fade">

                                <h5 className="fw-semibold mb-3 text-capitalize fs-23">All <span
                                    className="font-caveat text-primary">Category</span></h5>

                                <div className="tags-inner d-flex flex-wrap gap-2">
                                    <Link to={'/'} className="card fw-semibold rounded tag text-uppercase ui">Repair and
                                        Restoration</Link>
                                    <Link to={'/'} className="card fw-semibold rounded tag text-uppercase ui">News and
                                        Trends</Link>
                                    <Link to={'/'} className="card fw-semibold rounded tag text-uppercase ui">Export and
                                        Delivery</Link>
                                    <Link to={'/'} className="card fw-semibold rounded tag text-uppercase ui">Legal Aspects
                                        and Procedures</Link>
                                    <Link to={'/'} className="card fw-semibold rounded tag text-uppercase ui">Technical
                                        Review</Link>
                                    <Link to={'/'} className="card fw-semibold rounded tag text-uppercase ui">Buying
                                        Tips</Link>


                                </div>

                            </div>

                            <div className="position-relative dark-overlay rounded-4 overflow-hidden me-xxl-5 aos-init"
                                 data-aos="fade">
                                <div
                                    className="position-absolute w-100 text-center top-0 start-50 translate-middle-x pt-4 position-relative z-1">
                                    <h4 className="pt-3 mb-0 text-white">Your banner<br/> here!</h4>
                                </div>
                                <img className="img-fluid rounded-4 w-100"
                                     src="https://themebull.net/html/liston/v2.4/assets/images/banner.jpg"
                                     alt="Banner"/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogPageBlock;