import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {HeaderNotHome, HomeFooter, NewsTitleBlok, NewsSingleContent} from "./";


function NewsDetails({ match }) {
    const { title } = useParams();
    const [newsItem, setNewsItem] = useState(null);

    const navigate = useNavigate();

    const handleDelete = async () => {
        try {
            const response = await fetch(`https://dev.copia.vin/news/${title}`, {
                method: 'DELETE'
            });
            if (response.ok) {
                navigate('/'); // Перенаправляем пользователя на главную страницу после успешного удаления
            } else {
                console.error('Error deleting news:', response.statusText);
            }
        } catch (error) {
            console.error('Error deleting news:', error);
        }
    };
    useEffect(() => {
        const fetchNewsItem = async () => {
            try {
                const response = await fetch(`https://dev.copia.vin/news/${encodeURIComponent(title)}`);
                const data = await response.json();
                setNewsItem(data);
            } catch (error) {
                console.error('Error fetching news item:', error);
            }
        };

        fetchNewsItem();
    }, [title]);

    if (!newsItem) {
        return (//TODO
            <div>Loading...</div>);
    }

    return (
        <div>
            <HeaderNotHome/>
            <NewsTitleBlok title={newsItem.title} category={newsItem.category} date={newsItem.publish_date} />
            <NewsSingleContent content={newsItem.content}/>
            <button onClick={handleDelete}>Delite news</button>
            <HomeFooter/>
        </div>
    );
}

export default NewsDetails;
