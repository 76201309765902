import React from 'react';
import '../styles/BlogPage.css';
import {HeaderNotHome, PageHeader, HomeFooter,BlogPageBlock} from "./";

const BlogPage = () => {
    return (
        <div className="lot-details">
            <HeaderNotHome/>
            <PageHeader title="Hello- <span className='font-caveat text-white'>Welcome</span> 👋 to Blog" breadcrumb="Blog"/>
            <BlogPageBlock/>
            <HomeFooter/>
        </div>
    )
        ;
};

export default BlogPage;
