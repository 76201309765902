import React, {useEffect, useRef} from 'react';
import simpleParallax from 'simple-parallax-js';


import '../styles/HomeCTA.css';
const HomeCTA = () => {
    const contentRef = useRef(null);

    useEffect(() => {
        // Initialize simpleParallax
        const imageElements = document.querySelectorAll('.js-image-parallax');
        imageElements.forEach(image => {
            new simpleParallax(image, {
                delay: 0.6,
                transition: 'cubic-bezier(0,0,0,1)'
            });
        });

        // Add sticky sidebar effect
        const content = contentRef.current;

        if (content) {
            const sidebar = content.querySelector('.sidebar');
            const additionalMarginTop = 90;
            const resizeHandler = () => {
                const windowHeight = window.innerHeight;
                const sidebarHeight = sidebar.offsetHeight;
                const contentHeight = content.offsetHeight;
                if (windowHeight > sidebarHeight + additionalMarginTop) {
                    sidebar.style.marginTop = additionalMarginTop + 'px';
                } else {
                    sidebar.style.marginTop = (windowHeight - contentHeight) + 'px';
                }
            };

            window.addEventListener('resize', resizeHandler);
            resizeHandler();

            return () => {
                window.removeEventListener('resize', resizeHandler);
            };
        }
    }, [contentRef]);
    return (
        <div className="container pt-4">
            <div className="row justify-content-center">
        <div className="banner dark-overlay mt-5 overflow-hidden position-relative rounded-4">
            <div className="simpleParallax simple-parallax-initialized" style={{ overflow: 'hidden' }}>
                <img src="https://autocredo.com.ua/images/new_design/questions_bg.jpg" className="bg-image js-image-parallax" alt="" style={{ transform: 'translate3d(0px, 8px, 0px) scale(1.3)', willChange: 'transform', transition: 'transform 0.6s cubic-bezier(0, 0, 0, 1) 0s' }} />
            </div>
            <div className="align-items-center g-0 h-100 p-3 p-sm-5 position-relative row text-white wrapper z-1">
                <div className="col-md-5">
                    <img src="https://themebull.net/html/liston/v2.4/assets/images/icon/mercedes-benz.png" alt="" height="80" />
                    <h2 className="fw-semibold">Electric crafted by mercedes-benz. The all electric range</h2>
                    <p>Starting form USD 249,000</p>
                    <a href="grid-listing-filterscol.html" className="btn btn-primary">Call Now !</a>
                </div>
            </div>
            {/* /wrapper */}
        </div>
        </div>
        </div>
    );
};

export default HomeCTA;
