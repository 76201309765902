import React, {useState} from 'react';
import { useNavigate} from 'react-router-dom';
import '../styles/VinDecodingForm.css';
import {VinDecodingDetails} from "./";


const VinDecodingForm = () => {
    const [vin, setVin] = useState('');
    const [vinData] = useState(null);
    const navigate = useNavigate();

    const handleChange = (event) => {
        setVin(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Запрос на получение информации о VIN
        const response = await fetch(`https://dev.copia.vin/vin-decoding/${vin}`);
        if (response.ok) {
            // Если запрос успешен, получаем данные
            const data = await response.json();
            // Получаем марку и модель из ответа
            const { make, model } = data;
            // Заменяем пробелы на дефисы в модели
            const formattedModel = model.replace(/ /g, '-');
            // Переходим на страницу с информацией о VIN, включая марку и модель с измененными пробелами в адрес
            navigate(`/vin-decoding/${make}/${formattedModel}/${vin}`);
        } else {
            console.error('Ошибка при получении информации');
        }
    };
    return (
        <div class="py-5 bg-light mx-3 rounded-4 my-3">
            <div class="container py-5">
                <div class="row justify-content-between">
                    <div class="col-md-12 col-xl-12">
                        <h3 class="h1 mb-4 font-caveat text-primary">Free VIN Decoder</h3>
                        <form onSubmit={handleSubmit} class="col-mb-6 col-xl-6">
                            <input type="text" class="form-control" value={vin} onChange={handleChange}
                                   placeholder="Enter 17 digit VIN number" required=""/>
                            <button type="submit" className="btn btn-primary btn-lg d-inline-flex hstack gap-2 mt-4">
                                <span>Decode</span>
                                <span className="vr"></span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24"
                                     fill="none">
                                    <path
                                        d="M16 11L17.6965 12.5268C19.239 13.9151 20.0103 14.6093 20.0103 15.5C20.0103 16.3907 19.239 17.0849 17.6965 18.4732L16 20"
                                        stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/>
                                    <path d="M13.9868 5L11.9934 12.5L10 20" stroke="#ffffff" stroke-width="1.5"
                                          stroke-linecap="round"/>
                                    <path
                                        d="M8.00005 4.82959L6.30358 6.35641C4.76102 7.74471 3.98975 8.43886 3.98975 9.32959C3.98975 10.2203 4.76102 10.9145 6.30358 12.3028L8.00005 13.8296"
                                        stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/>
                                </svg>
                            </button>
                        </form>
                        {vinData && <VinDecodingDetails vinData={vinData}/>}
                    </div>

                </div>

            </div>
        </div>


    )
        ;
};

export default VinDecodingForm;
