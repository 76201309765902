import React from 'react';
import '../styles/LotCard.css';

const SearchBarCatalogue = () => {
    return (
        <div className="border-bottom border-top p-3 p-xl-0 search-bar">
            <div className="row g-3 g-xl-0">
                <div className="col-12 d-xl-none">
                    <div className="collapse show" id="CollapseText">
                        <h2 className="fw-semibold text-center search-bar-title mb-0">Find what<br /> you <span className="font-caveat text-primary">want</span></h2>
                    </div>
                </div>
                <div className="col-md-8 col-lg-5 col-xl-6">
                    <div className="search-select-input has-icon has-icon-y position-relative">
                        <input className="form-control" type="text" placeholder="Enter 17 digit VIN number or LOT"/>

                        <svg className="form-icon-start position-absolute top-50 bi bi-pin-map-fill" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-0.5 0 25 25"
                             fill="none">
                            <path
                                d="M1.99023 8.56V16.56C1.99023 17.6209 2.41166 18.6383 3.1618 19.3884C3.91195 20.1386 4.92937 20.56 5.99023 20.56H17.9902C19.0511 20.56 20.0685 20.1386 20.8187 19.3884C21.5688 18.6383 21.9902 17.6209 21.9902 16.56V8.56C21.9902 7.49913 21.5688 6.48171 20.8187 5.73157C20.0685 4.98142 19.0511 4.56 17.9902 4.56H5.99023C4.92937 4.56 3.91195 4.98142 3.1618 5.73157C2.41166 6.48171 1.99023 7.49913 1.99023 8.56Z"
                                stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M5.99023 8.56V16.56" stroke="#000000" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M8.99023 12.56V16.56" stroke="#000000" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M8.99023 8.56V9.56" stroke="#000000" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M14.9902 8.56V16.56" stroke="#000000" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M12 16.5V16.56" stroke="#000000" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M12 8.56V13.56" stroke="#000000" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M17.9902 13.56V16.56" stroke="#000000" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M17.9902 8.56V10.56" stroke="#000000" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                        </svg>

                    </div>
                </div>
                <div className="col-md-2 col-lg-2 col-xl-3 d-none d-lg-block">
                    <div className="search-select has-icon position-relative">
                        <select className="select2 form-select select2-hidden-accessible"
                                aria-label="Default select example" data-select2-id="select2-data-1-x1hm" tabIndex="-1"
                                aria-hidden="true">
                            <option selected="" data-select2-id="select2-data-3-9f34">Select Location</option>
                            <option value="1">It &amp; Development</option>
                            <option value="2">Web &amp; Mobile Dev</option>
                            <option value="3">Writing</option>
                            <option value="4">Sales &amp; Marketing</option>
                            <option value="5">Music &amp; Audio</option>
                        </select>
                        <span className="select2 select2-container select2-container--bootstrap-5" dir="ltr"
                              data-select2-id="select2-data-2-4wrz">
                            <span className="selection">
                                <span className="select2-selection select2-selection--single" role="combobox"
                                      aria-haspopup="true" aria-expanded="false" tabIndex="0" aria-disabled="false"
                                      aria-labelledby="select2-856e-container" aria-controls="select2-856e-container">
                                    <span className="select2-selection__rendered" id="select2-856e-container"
                                          role="textbox" aria-readonly="true" title="Select Location">Select Location</span>
                                    <span className="select2-selection__arrow" role="presentation"><b role="presentation"></b></span>
                                </span>
                            </span>
                            <span className="dropdown-wrapper" aria-hidden="true"></span>
                        </span>
                        <svg className="form-icon-start position-absolute top-50 search-icon z-1 bi bi-geo-alt" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"></path>
                            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
                        </svg>
                    </div>
                </div>
                <div className="col-md-2 col-lg-2 col-xl-3 d-none d-lg-block">
                    <div className="search-select has-icon position-relative">
                        <select className="select2 form-select select2-hidden-accessible" aria-label="Default select example" data-select2-id="select2-data-4-8xfg" tabIndex="-1" aria-hidden="true">
                            <option selected="" data-select2-id="select2-data-6-mgj1">All Categories</option>
                            <option value="1">It &amp; Development</option>
                            <option value="2">Web &amp; Mobile Dev</option>
                            <option value="3">Writing</option>
                            <option value="4">Sales &amp; Marketing</option>
                            <option value="5">Music &amp; Audio</option>
                        </select>
                        <span className="select2 select2-container select2-container--bootstrap-5" dir="ltr" data-select2-id="select2-data-5-64v8">
                            <span className="selection">
                                <span className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex="0" aria-disabled="false" aria-labelledby="select2-3dvm-container" aria-controls="select2-3dvm-container">
                                    <span className="select2-selection__rendered" id="select2-3dvm-container" role="textbox" aria-readonly="true" title="All Categories">All Categories</span>
                                    <span className="select2-selection__arrow" role="presentation"><b role="presentation"></b></span>
                                </span>
                            </span>
                            <span className="dropdown-wrapper" aria-hidden="true"></span>
                        </span>
                        <svg className="form-icon-start position-absolute top-50 search-icon z-1 bi bi-app-indicator" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"></path>
                            <path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"></path>
                        </svg>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-mg-3 d-xl-none gap-3 gap-md-2 hstack justify-content-center">
                    <div  className="sidebarCollapse align-items-center d-flex justify-content-center filters-text fw-semibold gap-2">
                        <i className="fa-solid fa-arrow-up-short-wide fs-18"></i>
                        <span>All filters</span>
                    </div>
                    <div className="h-75 mt-auto vr d-md-none"></div>
                    <div id="mapCollapse" className="align-items-center d-flex justify-content-center filters-text fw-semibold gap-2">
                        <i className="fa-solid fa-map-location-dot fs-18"></i>
                        <span>Map</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchBarCatalogue;
