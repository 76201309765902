import React  from 'react';

import '../styles/AddNews.css';

import {HeaderNotHome, HomeFooter, PageHeader,AddNewsForm} from "./";


function AddNews() {



    return (
        <div>
            <HeaderNotHome/>
            <PageHeader title="Add <span className='font-caveat text-white'>News</span> 📰 to Blog" breadcrumb="Add news" />
            <AddNewsForm/>
            <HomeFooter/>
        </div>
    );
}


export default AddNews;
