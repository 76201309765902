import React, { useState } from 'react';
import axios from 'axios';
function AddLotForm() {
    const [vin, setVin] = useState('');
    const [lotNumber, setLotNumber] = useState('');
    const [attributes, setAttributes] = useState([{ name: '', value: '' }]);
    const [parameters, setParameters] = useState([{ name: '', value: '' }]);
    const [photo, setPhoto] = useState(null);

    const handleAttributeChange = (index, event) => {
        const updatedAttributes = [...attributes];
        updatedAttributes[index][event.target.name] = event.target.value;
        setAttributes(updatedAttributes);
    };

    const handleParameterChange = (index, event) => {
        const updatedParameters = [...parameters];
        updatedParameters[index][event.target.name] = event.target.value;
        setParameters(updatedParameters);
    };

    const handleAttributeAdd = () => {
        setAttributes([...attributes, { name: '', value: '' }]);
    };

    const handleParameterAdd = () => {
        setParameters([...parameters, { name: '', value: '' }]);
    };

    const handlePhotoChange = (event) => {
        setPhoto(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const formData = new FormData();
            formData.append('vin', vin);
            formData.append('lotnumber', lotNumber);
            attributes.forEach((attr, index) => {
                formData.append('attribute_names[]', attr.name);
                formData.append('attribute_values[]', attr.value);
            });
            parameters.forEach((param, index) => {
                formData.append('parameter_names[]', param.name);
                formData.append('parameter_values[]', param.value);
            });
            formData.append('photo', photo);

            const response = await axios.post('https://dev.copia.vin/addlots', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log(response.data);
            // Дополнительные действия после успешного добавления лота

        } catch (error) {
            console.error('Error occurred while adding lot:', error);
            // Обработка ошибки
        }
    };

    return (
        <div>
            <h2>Add Lot</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>VIN:</label>
                    <input type="text" value={vin} onChange={(e) => setVin(e.target.value)} />
                </div>
                <div>
                    <label>Lot Number:</label>
                    <input type="text" value={lotNumber} onChange={(e) => setLotNumber(e.target.value)} />
                </div>
                <div>
                    <label>Attributes:</label>
                    {attributes.map((attr, index) => (
                        <div key={index}>
                            <input
                                type="text"
                                name="name"
                                value={attr.name}
                                onChange={(e) => handleAttributeChange(index, e)}
                                placeholder="Attribute Name"
                            />
                            <input
                                type="text"
                                name="value"
                                value={attr.value}
                                onChange={(e) => handleAttributeChange(index, e)}
                                placeholder="Attribute Value"
                            />
                        </div>
                    ))}
                    <button type="button" onClick={handleAttributeAdd}>Add Attribute</button>
                </div>
                <div>
                    <label>Parameters:</label>
                    {parameters.map((param, index) => (
                        <div key={index}>
                            <input
                                type="text"
                                name="name"
                                value={param.name}
                                onChange={(e) => handleParameterChange(index, e)}
                                placeholder="Parameter Name"
                            />
                            <input
                                type="text"
                                name="value"
                                value={param.value}
                                onChange={(e) => handleParameterChange(index, e)}
                                placeholder="Parameter Value"
                            />
                        </div>
                    ))}
                    <button type="button" onClick={handleParameterAdd}>Add Parameter</button>
                </div>
                <div>
                    <label>Photo:</label>
                    <input type="file" onChange={handlePhotoChange} />
                </div>
                <button type="submit">Submit</button>
            </form>
        </div>
    );
}


export default AddLotForm;
