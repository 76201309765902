import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/HomeBrandBlock.css';

const HomeBrandBlock = () => {
    return (
        <div className="py-5 position-relative overflow-hidden mx-3 mb-3 rounded-4">
            <div className="container py-4">
                <div className="row justify-content-center">
                    <div className="col-sm-10 col-md-10 col-lg-8 col-xl-7">
                        {/* start section header */}
                        <div className="section-header text-center mb-5 aos-init aos-animate" data-aos="fade-down">
                            {/* start subtitle */}
                            <div
                                className="d-inline-block font-caveat fs-1 fw-medium section-header__subtitle text-capitalize text-primary">Brands
                            </div>
                            {/* end /. subtitle */}
                            {/* start title */}
                            <h2 className="display-5 fw-semibold mb-3 section-header__title text-capitalize">Prices,
                                Specs &amp; Features of Popular Brands in ListOn</h2>
                            {/* end /. title */}
                            {/* start description */}
                            <div className="sub-title fs-16">Discover exciting categories. <span
                                className="text-primary fw-semibold">Find what you’re looking for.</span></div>
                            {/* end /. description */}
                        </div>
                        {/* end /. section header */}
                    </div>
                </div>
                <div className="row align-items-center justify-content-center g-2 g-sm-3 g-md-4 mb-5">
                    <div className="col-6 col-sm-4 col-lg-3 col-xxl-2">
                        <Link to="listings-map-car.html"
                              className="border-0 card card-hover d-block header-cat-box px-3 py-3 rounded-4 text-center">
                            <img src="https://themebull.net/html/liston/v2.4/assets/images/brand-logo/toyota-car.png"
                                 alt="" height="60" className="company-logo img-fluid"/>
                            <div className="fw-semibold mt-1">Toyota</div>
                        </Link>


                    </div>
                    <div className="col-6 col-sm-4 col-lg-3 col-xxl-2 ">

                        <Link to="listings-map-car.html"
                              className="border-0 card card-hover d-block header-cat-box px-3 py-3 rounded-4 text-center">
                            <img src="https://themebull.net/html/liston/v2.4/assets/images/brand-logo/hyundai-logo.png"
                                 alt="" height="60" className="company-logo img-fluid"/>
                            <div className="fw-semibold mt-1">Hyundai</div>
                        </Link>

                    </div>
                    <div className="col-6 col-sm-4 col-lg-3 col-xxl-2">

                        <Link to="listings-map-car.html"
                              className="border-0 card card-hover d-block header-cat-box px-3 py-3 rounded-4 text-center">
                            <img src="https://themebull.net/html/liston/v2.4/assets/images/brand-logo/nissan-logo.png"
                                 alt="" height="60" className="company-logo img-fluid"/>
                            <div className="fw-semibold mt-1">Nissan</div>
                        </Link>

                    </div>
                    <div className="col-6 col-sm-4 col-lg-3 col-xxl-2">

                        <Link to="listings-map-car.html"
                              className="border-0 card card-hover d-block header-cat-box px-3 py-3 rounded-4 text-center">
                            <img src="https://themebull.net/html/liston/v2.4/assets/images/brand-logo/bmw.png"
                                 alt="" height="60" className="company-logo img-fluid"/>
                            <div className="fw-semibold mt-1">BMW</div>
                        </Link>

                    </div>
                    <div className="col-6 col-sm-4 col-lg-3 col-xxl-2">

                        <Link to="listings-map-car.html"
                              className="border-0 card card-hover d-block header-cat-box px-3 py-3 rounded-4 text-center">
                            <img src="https://themebull.net/html/liston/v2.4/assets/images/brand-logo/mercedes-benz.png"
                                 alt="" height="60" className="company-logo img-fluid"/>
                            <div className="fw-semibold mt-1">Mercedes Benz</div>
                        </Link>

                    </div>
                    <div className="col-6 col-sm-4 col-lg-3 col-xxl-2">

                        <Link to="listings-map-car.html"
                              className="border-0 card card-hover d-block header-cat-box px-3 py-3 rounded-4 text-center">
                            <img src="https://themebull.net/html/liston/v2.4/assets/images/brand-logo/ford.png"
                                 alt="" height="60" className="company-logo img-fluid"/>
                            <div className="fw-semibold mt-1">Ford</div>
                        </Link>

                    </div>
                    <div className="col-6 col-sm-4 col-lg-3 col-xxl-2">

                        <Link to="listings-map-car.html"
                              className="border-0 card card-hover d-block header-cat-box px-3 py-3 rounded-4 text-center">
                            <img src="https://themebull.net/html/liston/v2.4/assets/images/brand-logo/honda.png"
                                 alt="" height="60" className="company-logo img-fluid"/>
                            <div className="fw-semibold mt-1">Honda</div>
                        </Link>

                    </div>
                    <div className="col-6 col-sm-4 col-lg-3 col-xxl-2">

                        <Link to="listings-map-car.html"
                              className="border-0 card card-hover d-block header-cat-box px-3 py-3 rounded-4 text-center">
                            <img src="https://themebull.net/html/liston/v2.4/assets/images/brand-logo/mitsubishi.png"
                                 alt="" height="60" className="company-logo img-fluid"/>
                            <div className="fw-semibold mt-1">Mitsubishi</div>
                        </Link>

                    </div>
                    <div className="col-6 col-sm-4 col-lg-3 col-xxl-2">

                        <Link to="listings-map-car.html"
                              className="border-0 card card-hover d-block header-cat-box px-3 py-3 rounded-4 text-center">
                            <img src="https://themebull.net/html/liston/v2.4/assets/images/brand-logo/lexus.png"
                                 alt="" height="60" className="company-logo img-fluid"/>
                            <div className="fw-semibold mt-1">Lexus</div>
                        </Link>

                    </div>
                    <div className="col-6 col-sm-4 col-lg-3 col-xxl-2">

                        <Link to="listings-map-car.html"
                              className="border-0 card card-hover d-block header-cat-box px-3 py-3 rounded-4 text-center">
                            <img src="https://themebull.net/html/liston/v2.4/assets/images/brand-logo/audi.png"
                                 alt="" height="60" className="company-logo img-fluid"/>
                            <div className="fw-semibold mt-1">Audi</div>
                        </Link>

                    </div>
                    <div className="col-6 col-sm-4 col-lg-3 col-xxl-2">

                        <Link to="listings-map-car.html"
                              className="border-0 card card-hover d-block header-cat-box px-3 py-3 rounded-4 text-center">
                            <img src="https://themebull.net/html/liston/v2.4/assets/images/brand-logo/chevrolet.png"
                                 alt="" height="60" className="company-logo img-fluid"/>
                            <div className="fw-semibold mt-1">Chevrolet</div>
                        </Link>

                    </div>
                    <div className="col-6 col-sm-4 col-lg-3 col-xxl-2">

                        <Link to="listings-map-car.html"
                              className="border-0 card card-hover d-block header-cat-box px-3 py-3 rounded-4 text-center">
                            <img src="https://themebull.net/html/liston/v2.4/assets/images/brand-logo/kia.png"
                                 alt="" height="60" className="company-logo img-fluid"/>
                            <div className="fw-semibold mt-1">Kia</div>
                        </Link>

                    </div>

                </div>
                <div className="brand-button-centre">
                    <a href="blog.html"
                       className="btn btn-primary">View All
                        Posts</a></div>
            </div>
        </div>
    );
};

export default HomeBrandBlock;

