import React from 'react';


import {HeaderNotHome, HomeFooter, PageHeader,VinDecodingForm} from "./";


const VinDecodingPage = () => {


    return (
        <div>
            <HeaderNotHome/>
            <PageHeader title={"<span className='font-caveat text-white'>VIN</span> decoding"}/>
            <VinDecodingForm/>
            <HomeFooter/>
        </div>
    );
};

export default VinDecodingPage;
