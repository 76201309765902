import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import RecentCarouselCard from './RecentCarouselCard';


const RecentCarousel = () => {
    const [latestLots, setLatestLots] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://dev.copia.vin/latest-lots');
                setLatestLots(response.data);
            } catch (error) {
                console.error('Error fetching latest lots:', error);
            }
        };

        fetchData();
    }, [latestLots]); // Этот useEffect будет выполняться только один раз после монтирования компонента

   // Этот useEffect будет выполняться каждый раз, когда изменяется latestLots
    const sliderSettings = {
        dots: false, // Отображать точки для навигации
        infinite: true, // Бесконечная прокрутка
        speed: 500, // Скорость прокрутки (в миллисекундах)
        slidesToShow: 3, // Количество слайдов, отображаемых одновременно
        slidesToScroll: 1, // Количество слайдов, прокручиваемых за раз
        autoplay: false, // Автоматическая прокрутка
        autoplaySpeed: 3000, // Интервал автоматической прокрутки (в миллисекундах)
        responsive: [
            {
                breakpoint: 1024, // Переопределение настроек для разрешения экрана <= 1024px
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768, // Переопределение настроек для разрешения экрана <= 768px
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };
    return (
        <Slider {...sliderSettings} className="owl-theme" margin={10}>
            {latestLots.map(lot => (

                <RecentCarouselCard key={lot.vin} lot={lot} />
            ))}
        </Slider>
    );
};

export default RecentCarousel;
