import React from 'react';
import { Link } from 'react-router-dom';


import '../styles/HomeRecentBlock.css';
import { RecentCarousel } from './';
const HomeRecentBlock = () => {
    return (

        <div className="py-5 bg-light rounded-4 mx-3 mb-3 position-relative overflow-hidden bg-size-contain js-bg-image js-bg-image-lines" data-image-src="assets/images/lines.svg">
            <div className="container py-4">
                <div className="align-items-end row g-4 mb-5 aos-init aos-animate" data-aos="fade-down">
                    <div className="col">
                        <div className="section-header">
                            <div className="font-caveat fs-1 fw-medium section-header__subtitle text-capitalize text-center text-primary text-xl-start">Recent lots</div>
                            <h2 className="fw-semibold mb-0 section-header__title text-capitalize text-center text-xl-start display-5">Recent lots</h2>
                            <div className="sub-title fs-16 text-center text-xl-start">Discover exciting categories. <span className="text-primary fw-semibold">Free history of sales, prices and damage insurance cars in the United States at Copart and insurance auctions IAAI</span></div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-auto">
                        <Link to="listings-map-car.html" className="align-items-center d-flex fs-14 fw-bold gap-2 justify-content-center justify-content-xl-end l-spacing-1 text-primary text-uppercase">
                            See All
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"></path>
                            </svg>
                        </Link>
                    </div>
                </div>
                <RecentCarousel/>

            </div>
        </div>
    );
};

export default HomeRecentBlock;
