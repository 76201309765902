import React, { useState } from 'react';
import { SearchPageHeader, HomeFooter, SearchAutoSitebar, SearchAutoLotList } from './';
import '../styles/bootstrap.min.css';
import '../styles/main.css';

const SearchAuto = () => {
    const [filters, setFilters] = useState({ make: '', model: '' });

    const handleFilterChange = (newFilters) => {
        setFilters(newFilters);
    };

    return (
        <div className="lot-details">
            <SearchPageHeader />
            <div className="py-3 py-xl-5 bg-gradient">
                <div className="container">
                    <div className="row">
                        <SearchAutoSitebar applyFilter={handleFilterChange} />
                        <SearchAutoLotList filters={filters} />
                    </div>
                </div>
            </div>
            <HomeFooter />
        </div>
    );
};

export default SearchAuto;
