import React, { useState, useEffect } from 'react';

import axios from 'axios';
import '../styles/LotCard.css';
const SearchAutoSitebar = ({ applyFilter }) => {

    const [makes, setMakes] = useState([]);
    const [auctions, setAuctions] = useState([]);
    const [models, setModels] = useState([]);
    const [selectedMake, setSelectedMake] = useState('');
    const [selectedAuction, setSelectedAuction] = useState('');
    const [selectedModel, setSelectedModel] = useState('');

    useEffect(() => {
        fetchMakes();

    }, []);
    useEffect(() => {

        fetchAuctions();
    }, []);

    const fetchMakes = async () => {
        try {
            const response = await axios.get('https://dev.copia.vin/makes');
            setMakes(response.data);
        } catch (error) {
            console.error('Error fetching makes:', error);
        }
    };
    const fetchAuctions = async () => {
        try {
            const response = await axios.get('https://dev.copia.vin/auctions');
            setAuctions(response.data);
        } catch (error) {
            console.error('Error fetching auctions:', error);
        }
    };

    const fetchModels = async (make) => {
        try {
            const response = await axios.get(`https://dev.copia.vin/models?make=${make}`);
            setModels(response.data);
        } catch (error) {
            console.error('Error fetching models:', error);
        }
    };


    const handleMakeChange = (make) => {
        setSelectedMake(make);
        setSelectedModel('');
        fetchModels(make);
    };
    const handleAuctionChange = (auction) => {
        setSelectedAuction(auction);

        fetchAuctions(auction);
    };

    const handleModelChange = (model) => {
        setSelectedModel(model);
    };

    const handleApplyFilter = () => {
        applyFilter({ make: selectedMake, model: selectedModel, auction: selectedAuction });
    };
    return (




            <aside className="col-xl-3 filters-col content pe-lg-4 pe-xl-5 shadow-end">
                <div className="theiaStickySidebar">
                    <div className="js-sidebar-filters-mobile">


                        <div
                            className="border-bottom d-flex justify-content-between align-items-center p-3 sidebar-filters-header d-xl-none">
                            <div
                                className="align-items-center btn-icon d-flex filter-close justify-content-center rounded-circle">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                     fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path
                                        d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"></path>
                                </svg>
                            </div>
                            <span className="fs-3 fw-semibold">Filters</span>
                            <span className="text-primary fw-medium">Clear</span>
                        </div>

                        <div className="sidebar-filters-body p-3 p-xl-0">
                            <div className="mb-4 border-bottom pb-4">
                                <div className="mb-3">
                                    <h4 className="fs-5 fw-semibold mb-1">Price Filter</h4>
                                    <p className="mb-0 small">Select min and max price range</p>
                                </div>

                                <div className="js-range-slider irs-hidden-input" tabIndex="-1"></div>

                            </div>
                            <div className="mb-4 border-bottom pb-4">
                                <div className="mb-3">
                                    <h4 className="fs-5 fw-semibold mb-1">Filter</h4>
                                    <p className="mb-0 small">Duis a leo sit amet odio volutpat auctor ut a
                                        lorem.</p>
                                </div>
                                <select id="auction" value={selectedAuction} className="form-select"
                                        onChange={(e) => handleAuctionChange(e.target.value)}>
                                    <option value="">Auction</option>
                                    {Array.isArray(auctions) && auctions.map((auction) => (
                                        <option key={auction} value={auction}>{auction}</option>
                                    ))}
                                </select><br/>
                                <select id="make" value={selectedMake} className="form-select"
                                        onChange={(e) => handleMakeChange(e.target.value)}>
                                    <option value="">Make</option>
                                    {Array.isArray(makes) && makes.map((make) => (
                                        <option key={make} value={make}>{make}</option>
                                    ))}
                                </select>
                                <h4 className="fs-5 fw-semibold mb-1">Model</h4>
                                <select id="model" value={selectedModel} className="form-select"
                                        onChange={(e) => handleModelChange(e.target.value)}
                                        disabled={!selectedMake}>
                                    <option value="">Model</option>
                                    {models.map((model) => (
                                        <option key={model} value={model}>{model}</option>
                                    ))}
                                </select>


                            </div>
                            {/*<div className="mb-4 border-bottom pb-4">*/}
                            {/*    <div className="mb-3">*/}
                            {/*        <h4 className="fs-5 fw-semibold mb-2">Categories</h4>*/}
                            {/*        <p className="mb-0 small">Duis a leo sit amet odio volutpat auctor ut a*/}
                            {/*            lorem.</p>*/}
                            {/*    </div>*/}
                            {/*    <div className="form-check mb-2">*/}
                            {/*        <input className="form-check-input" type="checkbox" value=""*/}
                            {/*               id="skillsOne"/>*/}
                            {/*        <label className="form-check-label"*/}
                            {/*               htmlFor="skillsOne">Eat &amp; Drink<span*/}
                            {/*            className="count fs-13 ms-1 text-muted">(62)</span></label>*/}
                            {/*    </div>*/}
                            {/*    <div className="form-check mb-2">*/}
                            {/*        <input className="form-check-input" type="checkbox" value=""*/}
                            {/*               id="skillsTwo" checked=""/>*/}
                            {/*        <label className="form-check-label" htmlFor="skillsTwo">Coaching<span*/}
                            {/*            className="count fs-13 ms-1 text-muted">(31)</span></label>*/}
                            {/*    </div>*/}
                            {/*    <div className="form-check mb-2">*/}
                            {/*        <input className="form-check-input" type="checkbox" value=""*/}
                            {/*               id="skillsThree"/>*/}
                            {/*        <label className="form-check-label"*/}
                            {/*               htmlFor="skillsThree">Apartments<span*/}
                            {/*            className="count fs-13 ms-1 text-muted">(20)</span></label>*/}
                            {/*    </div>*/}
                            {/*    <div className="form-check mb-2">*/}
                            {/*        <input className="form-check-input" type="checkbox" value=""*/}
                            {/*               id="skillsFour"/>*/}
                            {/*        <label className="form-check-label" htmlFor="skillsFour">Services<span*/}
                            {/*            className="count fs-13 ms-1 text-muted">(43)</span></label>*/}
                            {/*    </div>*/}
                            {/*    <div className="form-check mb-2">*/}
                            {/*        <input className="form-check-input" type="checkbox" value=""*/}
                            {/*               id="skillsFive"/>*/}
                            {/*        <label className="form-check-label"*/}
                            {/*               htmlFor="skillsFive">Classifieds<span*/}
                            {/*            className="count fs-13 ms-1 text-muted">(16)</span></label>*/}
                            {/*    </div>*/}
                            {/*    <div className="form-check mb-2">*/}
                            {/*        <input className="form-check-input" type="checkbox" value=""*/}
                            {/*               id="skillsSix"/>*/}
                            {/*        <label className="form-check-label" htmlFor="skillsSix">Fitness<span*/}
                            {/*            className="count fs-13 ms-1 text-muted">(22)</span></label>*/}
                            {/*    </div>*/}
                            {/*    <div className="form-check mb-2">*/}
                            {/*        <input className="form-check-input" type="checkbox" value=""*/}
                            {/*               id="skillsSeven"/>*/}
                            {/*        <label className="form-check-label" htmlFor="skillsSeven">Events<span*/}
                            {/*            className="count fs-13 ms-1 text-muted">(21)</span></label>*/}
                            {/*    </div>*/}
                            {/*    <div className="form-check mb-0">*/}
                            {/*        <input className="form-check-input" type="checkbox" value=""*/}
                            {/*               id="skillsEight"/>*/}
                            {/*        <label className="form-check-label" htmlFor="skillsEight">Other<span*/}
                            {/*            className="count fs-13 ms-1 text-muted">(17)</span></label>*/}
                            {/*    </div>*/}

                            {/*</div>*/}


                            <button type="button" onClick={handleApplyFilter}
                                    className="btn btn-primary w-100">Apply filters
                            </button>
                            <div
                               className="align-items-center d-flex fw-medium gap-2 justify-content-center mt-2 small text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                     fill="currentColor" className="bi bi-arrow-clockwise"
                                     viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                          d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"></path>
                                    <path
                                        d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"></path>
                                </svg>
                                Clear filters
                            </div>

                        </div>
                    </div>

                </div>
            </aside>


    )
        ;
};

export default SearchAutoSitebar;
