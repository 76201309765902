import React from 'react';
import { HeaderHome,HomeCategoriesBlock,HomeBrandBlock,HeaderNavbar,HomeRecentBlock,HomeCTA,HomeBlogBlock,HomeFooter } from "./";



const Home = () => {


    return (
        <div className="lot-details">
            <HeaderNavbar/>
            <HeaderHome/>
            <HomeCategoriesBlock/>
            <HomeBrandBlock/>
            <HomeRecentBlock/>
            <HomeCTA/>
            <HomeBlogBlock/>
            <HomeFooter/>


        </div>
    );
};

export default Home;
