import React from 'react';
import '../styles/SignIn.css';
import {
    HeaderNotHome,
    SignInBlock
} from "./";
const SignIn = () => {
    return (
        <div className="lot-details">
    <HeaderNotHome/>
    <SignInBlock/>
        </div>
)
    ;
};

export default SignIn;
