import React from 'react';
import '../styles/BlogPage.css';

const BlogPage = (data) => {
    return (
        <section className="dark-overlay hero mx-3 overflow-hidden position-relative py-4 py-lg-5 rounded-4 text-white">
            <img className="bg-image" src="https://images.pexels.com/photos/3311574/pexels-photo-3311574.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="img"/>
            <div className="container overlay-content py-5">
                <div className="row justify-content-center">
                    <div className="col-lg-10 col-xl-10 text-center">

                        <div
                            className="bg-primary d-inline-block fs-14 mb-3 px-4 py-2 rounded-5 sub-title text-white text-uppercase">{data.category}
                            / {data.date}
                        </div>
                        <h2 className="display-4 fw-semibold section-header__title text-capitalize mb-0">{data.title}</h2>
                    </div>
                </div>
            </div>
        </section>
    )
        ;
};

export default BlogPage;
