import React, { useState, useEffect } from 'react';
import {Link, useParams} from 'react-router-dom';
import axios from 'axios';
import {HeaderNotHome, HomeFooter} from "./index";
import Slider from "react-slick";
import '../styles/LotDetails.css';


const LotDetails = () => {
    const { vin } = useParams(); // Получаем параметр id из URL-адреса
    const [lot, setLot] = useState(null);

    useEffect(() => {
        const fetchLot = async () => {
            try {
                const response = await axios.get(`https://dev.copia.vin/lots/${vin}`);
                setLot(response.data);
            } catch (error) {
                console.error('Error fetching lot details:', error);
            }
        };

        fetchLot();
        updateBackgroundImages();


    }, [vin]);
     // Выводим lot в консоль для отладки


    const updateBackgroundImages = () => {
        const elements = document.querySelectorAll('.fullwidth-property-slider .item');
        elements.forEach(element => {
            const backgroundImage = element.getAttribute('data-background-image');
            if (backgroundImage) {
                element.style.backgroundImage = `url(${backgroundImage})`;
            }
        });
    };
    if (!lot) {
        // TODO
        return <div>Loading...</div>;
    }

    const images = lot.gallery.split(',');
    const settingsGal = {
        dots: true,
        infinite: true,
        speed: 500,
        centerMode: true,
        centerPadding: '20%',
        slidesToShow: 1,
        responsive: [
            {
                breakpoint: 1367,
                settings: {
                    centerPadding: '15%'
                }
            },
            {
                breakpoint: 993,
                settings: {
                    centerPadding: '0'
                }
            }
        ]
    };

    return (
        <div>
            <HeaderNotHome/>
            <Slider className="fullwidth-property-slider margin-bottom-50" {...settingsGal}>


                    {images.map((image, index) => (
                        <Link to={image}
                           className="item mfp-gallery"
                           data-background-image={image}
                           ></Link>

                        ))
                    }
            </Slider>
            <div className="py-4">
                <div className="container">
                    <div className="justify-content-between row align-items-center g-4">
                        <div className="col-lg col-xxl-8">
                        <h1 className="h2 page-header-title fw-semibold">{lot.attributes.YearMakeModelSeries}</h1>
                    <ul className="list-inline list-separator d-flex align-items-center mb-2">
                        <li className="list-inline-item">
                            <Link to={'/'} className="fw-medium" href="#">{lot.attributes.Make}<i
                                className="fa-solid fa-arrow-up-right-from-square fs-14 ms-2"></i></Link>
                        </li>
                        <li className="list-inline-item">
                                    <div className="d-flex align-items-center gap-2 ms-auto">
                                        <div className="d-flex align-items-center text-primary rating-stars">
                                            <Link to={'/'} className="fw-medium">{lot.attributes.Model}<i
                                                className="fa-solid fa-arrow-up-right-from-square fs-14 ms-2"></i></Link>
                                        </div>

                                        <span className="fw-medium text-primary"><span
                                            className="fs-6 fw-semibold me-1">{vin}</span></span>

                                    </div>
                                </li>
                            </ul>

                        </div>
                        <div className="col-lg-auto">

                            <div className="form-check form-check-bookmark mb-2 mb-sm-0">
                                <input className="form-check-input" type="checkbox" value="" id="jobBookmarkCheck"/>
                                <label className="form-check-label" htmlFor="jobBookmarkCheck">
                            <span className="form-check-bookmark-default">
                                <i className="fa-regular fa-heart me-1"></i> Save this listing
                            </span>

                                </label>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="py-5 bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 content">
                            <div className="mb-5 border-bottom pb-5">
                                <h4 className="fw-semibold fs-3 mb-4">Know This <span
                                    className="font-caveat text-primary">Vin</span></h4>
                                <div className="row justify-content-between g-4 mb-4">
                                    <div className="col-md-5">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0">
                                                <div
                                                    className="align-items-center border-0 box-icon card d-flex fs-5 justify-content-center rounded-3">

                                                    <i className="fa-regular fa-life-ring"></i>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <div className="fw-bold fs-15">100% tyre life remaining</div>
                                                <div className="fs-13 text-muted">2 tyres that still have 100% of their
                                                    tread life remaining
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0">
                                                <div
                                                    className="align-items-center border-0 box-icon card d-flex fs-5 justify-content-center rounded-3">
                                                    <i className="fa-solid fa-shield"></i>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <div className="fw-bold fs-15">Standard safety features</div>
                                                <div className="fs-13 text-muted">Equipped with 2 airbags and an
                                                    Antilock Braking System (ABS)
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0">
                                                <div
                                                    className="align-items-center border-0 box-icon card d-flex fs-5 justify-content-center rounded-3">
                                                    <i className="fa-solid fa-building-wheat"></i>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <div className="fw-bold fs-15">City Driven</div>
                                                <div className="fs-13 text-muted">Cars driven for shorter trips in
                                                    cities
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row g-4">
                                    <div className="col-auto col-lg-3">

                                        <div className="d-flex align-items-center text-dark">
                                            <div className="flex-shrink-0">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                     xmlnsXlink="http://www.w3.org/1999/xlink" // используется xmlnsXlink вместо xmlns:xlink
                                                     fill="#000000"
                                                     height="800px"
                                                     width="800px"
                                                     version="1.1"
                                                     id="Layer_1"
                                                     viewBox="0 0 512 512"
                                                     xmlSpace="preserve"
                                                     style={{
                                                         margin: 'auto',
                                                         width: 'auto',
                                                         height: '30',
                                                         display: 'block'
                                                     }}>
                                                    <g transform="translate(1 1)">
                                                        <g>
                                                            <path
                                                                d="M502.467,186.733h-34.133c-3.413,0-6.827,2.56-7.68,5.973l-23.893,70.827h-23.04l-23.04-38.4    c-1.707-2.56-4.267-4.267-7.68-4.267h-34.133v-25.6c0-5.12-3.413-8.533-8.533-8.533H280.6V152.6h25.6    c5.12,0,8.533-3.413,8.533-8.533v-51.2c0-5.12-3.413-8.533-8.533-8.533H152.6c-5.12,0-8.533,3.413-8.533,8.533v51.2    c0,5.12,3.413,8.533,8.533,8.533h25.6v34.133h-68.267c-2.56,0-4.267,0.853-5.973,2.56s-2.56,3.413-2.56,5.973v25.6H41.667    c-5.12,0-8.533,3.413-8.533,8.533v51.2H16.067V255c0-5.12-3.413-8.533-8.533-8.533S-1,249.88-1,255v68.267    c0,5.12,3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533v-25.6h17.067v68.267c0,5.12,3.413,8.533,8.533,8.533h64l31.573,47.787    c1.707,1.707,4.267,3.413,6.827,3.413H383c3.413,0,5.973-1.707,7.68-5.12l23.04-46.08h23.893l23.04,46.08    c1.707,3.413,4.267,5.12,7.68,5.12h34.133c5.12,0,8.533-3.413,8.533-8.533V195.267C511,190.147,507.587,186.733,502.467,186.733z     M161.133,101.4h136.533v34.133h-25.6h-85.333h-25.6V101.4z M195.267,152.6h68.267v34.133h-68.267V152.6z M50.2,357.4V237.933    h51.2V357.4H50.2z M377.027,408.6H148.333l-29.867-45.204V229.4v-25.6h68.267h85.333H331.8v25.6c0,5.12,3.413,8.533,8.533,8.533    h37.547l22.187,36.978v87.609L377.027,408.6z M417.133,357.4v-76.8H434.2v76.8H417.133z M493.933,408.6h-20.48l-22.187-42.789    v-93.714l23.04-68.297h19.627V408.6z"/>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="flex-grow-1 fs-16 fw-medium ms-3">
                                                <div className="fs-12 text-muted">Engine</div>
                                                <div className="fw-bold fs-15">{lot.attributes.EngineInformation}</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-auto col-lg-3">

                                        <div className="d-flex align-items-center text-dark">
                                            <div className="flex-shrink-0">
                                                <svg xmlns="http://www.w3.org/2000/svg" style={{
                                                    margin: 'auto',
                                                    width: 'auto',
                                                    height: '30',
                                                    display: 'block'
                                                }}
                                                     viewBox="0 0 24 24" fill="none">
                                                    <path
                                                        d="M3 10C3 8.11438 3 7.17157 3.58579 6.58579C4.17157 6 5.11438 6 7 6H17C18.8856 6 19.8284 6 20.4142 6.58579C21 7.17157 21 8.11438 21 10H3Z"
                                                        fill="#2A4157" fillOpacity="0.24"/>
                                                    <rect x="3" y="6" width="18" height="15" rx="2" stroke="#222222"
                                                          strokeWidth="1.2"/>
                                                    <path d="M7 3L7 6" stroke="#222222" strokeWidth="1.2"
                                                          strokeLinecap="round"/>
                                                    <path d="M17 3L17 6" stroke="#222222" strokeWidth="1.2"
                                                          strokeLinecap="round"/>
                                                    <rect x="7" y="12" width="4" height="2" rx="0.5" fill="#222222"/>
                                                    <rect x="7" y="16" width="4" height="2" rx="0.5" fill="#222222"/>
                                                    <rect x="13" y="12" width="4" height="2" rx="0.5" fill="#222222"/>
                                                    <rect x="13" y="16" width="4" height="2" rx="0.5" fill="#222222"/>
                                                </svg>
                                            </div>
                                            <div className="flex-grow-1 fs-16 fw-medium ms-3">
                                                <div className="fs-12 text-muted">Make Year</div>
                                                <div className="fw-bold fs-15">{lot.attributes.Year}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto col-lg-3">

                                        <div className="d-flex align-items-center text-dark">
                                            <div className="flex-shrink-0">
                                                <svg xmlns="http://www.w3.org/2000/svg" style={{
                                                    margin: 'auto',
                                                    width: 'auto',
                                                    height: '30',
                                                    display: 'block'
                                                }}
                                                     viewBox="-0.5 0 25 25" fill="none">
                                                    <path
                                                        d="M10.3365 12.3022C8.94652 13.1922 7.10652 13.2022 5.70652 12.3222C4.17652 11.3622 3.48652 9.72224 3.61652 8.14224C3.65652 7.64224 4.25653 7.41224 4.60653 7.76224L6.27653 9.44225C6.49653 9.67225 6.86653 9.67225 7.09653 9.44225L8.97652 7.54224C9.20652 7.31224 9.20652 6.94225 8.97652 6.72225L7.30652 5.04224C6.95652 4.69224 7.18652 4.08224 7.67652 4.04224C8.92652 3.94224 10.2165 4.36224 11.1665 5.32224C13.1365 7.31224 12.8565 10.7022 10.3365 12.3022Z"
                                                        stroke="#0F0F0F" stroke-miterlimit="10" stroke-linecap="round"
                                                        stroke-linejoin="round"/>
                                                    <path
                                                        d="M13.8966 11.7523L20.2566 18.1223C20.4466 18.3223 20.4466 18.6323 20.2566 18.8223L18.2666 20.8223C18.0666 21.0223 17.7466 21.0223 17.5566 20.8223L11.2266 14.4223"
                                                        stroke="#0F0F0F" stroke-miterlimit="10" stroke-linecap="round"
                                                        stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                            <div className="flex-grow-1 fs-16 fw-medium ms-3">
                                                <div className="fs-12 text-muted">Made In</div>
                                                <div className="fw-bold fs-15">{lot.attributes.CountryOfOrigin}</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-auto col-lg-3">

                                        <div className="d-flex align-items-center text-dark">
                                            <div className="flex-shrink-0">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                     fill="#000000" version="1.1" id="Layer_1"
                                                     viewBox="0 0 512 512" style={{
                                                    margin: 'auto',
                                                    width: 'auto',
                                                    height: '30',
                                                    display: 'block'
                                                }}>
                                                    <g>
                                                        <g>
                                                            <g>
                                                                <path
                                                                    d="M499.136,224.944L287.055,12.863c-17.124-17.123-44.987-17.123-62.111,0L12.863,224.944C4.568,233.24,0,244.27,0,256     c0,11.73,4.568,22.76,12.863,31.055l212.082,212.082c8.561,8.561,19.808,12.843,31.055,12.843     c11.246,0,22.493-4.28,31.055-12.843l212.082-212.082C507.431,278.76,512,267.73,512,256     C512,244.27,507.431,233.24,499.136,224.944z M487.847,275.763L275.765,487.846c-10.899,10.899-28.632,10.899-39.53,0     L24.153,275.763c-10.898-10.898-10.898-28.631,0-39.528L236.235,24.153c5.45-5.45,12.606-8.174,19.765-8.174     c7.157,0,14.317,2.725,19.765,8.174l212.082,212.082C498.744,247.134,498.744,264.866,487.847,275.763z"/>
                                                                <path
                                                                    d="M180.029,168.737c-3.118-3.117-8.173-3.117-11.289,0c-3.118,3.119-3.118,8.173,0,11.291l14.177,14.177     c1.558,1.558,3.602,2.338,5.645,2.338s4.086-0.779,5.645-2.338c3.118-3.119,3.118-8.173,0-11.291L180.029,168.737z"/>
                                                                <path
                                                                    d="M256,119.019c-4.409,0-7.983,3.573-7.983,7.983v33.626c0,4.41,3.574,7.983,7.983,7.983s7.983-3.573,7.983-7.983v-33.626     C263.983,122.592,260.409,119.019,256,119.019z"/>
                                                                <path
                                                                    d="M279.109,57.54c-6.173-6.174-14.38-9.573-23.109-9.573s-16.936,3.399-23.109,9.572L57.539,232.891     c-6.173,6.173-9.573,14.38-9.573,23.109c0,8.73,3.4,16.936,9.573,23.109L232.891,454.46c6.173,6.174,14.38,9.573,23.109,9.573     s16.936-3.399,23.109-9.572l175.352-175.352c6.173-6.173,9.573-14.38,9.573-23.109c0-8.73-3.4-16.936-9.573-23.109L279.109,57.54     z M68.829,267.817c-1.152-1.151-2.109-2.444-2.868-3.834h147.792l-18.967,129.792L68.829,267.817z M199.137,248.016     c3.899-27.9,27.906-49.447,56.863-49.447s52.964,21.547,56.863,49.447H199.137z M267.819,443.171     c-3.157,3.157-7.354,4.895-11.819,4.895s-8.661-1.738-11.819-4.896l-35.315-35.315l21.025-143.872h52.219l21.025,143.873     L267.819,443.171z M443.171,267.817L317.213,393.777l-18.967-129.794h147.792C445.28,265.374,444.322,266.667,443.171,267.817z      M328.955,248.015v0.001c-3.993-36.729-35.182-65.414-72.955-65.414s-68.962,28.685-72.955,65.414H65.961     c0.759-1.391,1.716-2.683,2.868-3.834L244.181,68.828c3.157-3.157,7.354-4.895,11.819-4.895s8.661,1.738,11.819,4.896     l175.352,175.352c1.152,1.151,2.109,2.444,2.868,3.834H328.955z"/>
                                                                <path
                                                                    d="M331.971,168.737l-14.177,14.177c-3.118,3.119-3.118,8.173,0,11.291c1.558,1.558,3.602,2.338,5.645,2.338     c2.043,0,4.086-0.779,5.645-2.338l14.177-14.177c3.118-3.119,3.118-8.173,0-11.291     C340.144,165.622,335.089,165.622,331.971,168.737z"/>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="flex-grow-1 fs-16 fw-medium ms-3">
                                                <div className="fs-12 text-muted">Highway Mileage</div>
                                                <div className="fw-bold fs-15">{vin.highway_mileage}</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-auto col-lg-3">

                                        <div className="d-flex align-items-center text-dark">
                                            <div className="flex-shrink-0">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                     fill="#000000"
                                                     height="800px" width="800px" version="1.1" id="Layer_1"
                                                     viewBox="0 0 512.002 512.002" style={{
                                                    margin: 'auto',
                                                    width: 'auto',
                                                    height: '30',
                                                    display: 'block'
                                                }}>
                                                    <g>
                                                        <g>
                                                            <g>
                                                                <circle cx="288.498" cy="402.417" r="7.021"/>
                                                                <circle cx="276.089" cy="420.54" r="7.021"/>
                                                                <circle cx="271.027" cy="395.397" r="7.022"/>
                                                                <path
                                                                    d="M400.197,233.866c12.327,0,22.356-10.029,22.356-22.356c0-12.326-10.029-22.355-22.356-22.355h-23.084V93.21h23.084     c12.327,0,22.356-10.029,22.356-22.356c0-12.326-10.029-22.355-22.356-22.355h-23.084v-3.648C377.113,20.12,356.994,0,332.262,0     H179.746c-24.731,0-44.851,20.121-44.851,44.851V48.5h-23.09c-12.327,0-22.356,10.029-22.356,22.355     c0,12.327,10.029,22.356,22.356,22.356h23.09v95.945h-23.09c-12.327,0-22.356,10.029-22.356,22.355     c0,12.327,10.029,22.356,22.356,22.356h23.09v95.945h-23.09c-12.327,0-22.356,10.029-22.356,22.355     c0,12.327,10.029,22.356,22.356,22.356h23.09v92.628c0,24.73,20.12,44.851,44.851,44.851h152.516     c24.731,0,44.851-20.121,44.851-44.851v-92.629h23.084c12.327,0,22.356-10.029,22.356-22.356     c0-12.326-10.029-22.355-22.356-22.355h-23.084v-95.945H400.197z M377.113,64.33h23.084c3.598,0,6.526,2.927,6.526,6.525     c0,3.599-2.927,6.526-6.526,6.526h-23.084V64.33z M377.113,204.985h23.084c3.598,0,6.526,2.927,6.526,6.525     c0,3.599-2.927,6.526-6.526,6.526h-23.084V204.985z M134.895,358.693h-23.09c-3.598,0-6.526-2.927-6.526-6.526     c0-3.598,2.927-6.525,6.526-6.525h23.09V358.693z M134.895,218.036h-23.09c-3.598,0-6.526-2.927-6.526-6.526     c0-3.598,2.927-6.525,6.526-6.525h23.09V218.036z M134.895,77.38h-23.09c-3.598,0-6.526-2.927-6.526-6.526     c0-3.598,2.927-6.525,6.526-6.525h23.09V77.38z M361.284,467.149c0,16.002-13.02,29.021-29.021,29.021H179.746     c-16.002,0-29.021-13.019-29.021-29.021V44.851c0-16.003,13.019-29.021,29.021-29.021h152.516     c16.002,0,29.021,13.02,29.021,29.021V467.149z M400.197,345.642c3.598,0,6.526,2.927,6.526,6.525     c0,3.599-2.927,6.526-6.526,6.526h-23.084v-13.051H400.197z"/>
                                                                <circle cx="276.089" cy="151.125" r="7.021"/>
                                                                <circle cx="271.027" cy="125.981" r="7.022"/>
                                                                <circle cx="288.498" cy="133.002" r="7.021"/>
                                                                <path
                                                                    d="M305.913,60.039h-99.815c-11.991,0-21.745,9.754-21.745,21.744c0,9.411,6.021,17.422,14.404,20.443     c-2.708,7.012-4.121,14.495-4.121,22.118c0,33.838,27.529,61.366,61.366,61.366c33.838,0,61.367-27.529,61.367-61.366     c0-7.624-1.412-15.106-4.12-22.116c8.386-3.019,14.408-11.031,14.408-20.445C327.657,69.794,317.902,60.039,305.913,60.039z      M256.002,169.881c-25.109,0-45.536-20.428-45.536-45.536c0-7.294,1.751-14.406,5.052-20.816h80.97     c3.301,6.41,5.052,13.522,5.052,20.816C301.539,149.453,281.111,169.881,256.002,169.881z M305.913,87.699h-99.815     c-3.262,0-5.915-2.653-5.915-5.915c0-3.261,2.654-5.914,5.915-5.914h99.815c3.262,0,5.914,2.653,5.914,5.914     S309.174,87.699,305.913,87.699z"/>
                                                                <path
                                                                    d="M302.713,29.549h-93.418c-24.731,0-44.851,20.121-44.851,44.851v363.2c0,24.73,20.12,44.851,44.851,44.851h93.418     c24.731,0,44.851-20.121,44.851-44.851V74.4C347.564,49.67,327.445,29.549,302.713,29.549z M331.735,437.6     c0,16.002-13.02,29.021-29.021,29.021h-93.418c-16.002,0-29.021-13.019-29.021-29.021V74.4c0-16.002,13.019-29.021,29.021-29.021     h93.418c16.002,0,29.021,13.018,29.021,29.021V437.6z"/>
                                                                <path
                                                                    d="M305.913,329.455h-99.815c-11.991,0-21.745,9.754-21.745,21.744c0,9.411,6.021,17.422,14.404,20.443     c-2.708,7.01-4.121,14.495-4.121,22.12c0,33.837,27.529,61.366,61.366,61.366c33.838,0,61.367-27.529,61.367-61.366     c0-7.624-1.412-15.107-4.12-22.117c8.386-3.019,14.408-11.031,14.408-20.444C327.657,339.209,317.902,329.455,305.913,329.455z      M256.002,439.297c-25.109,0-45.536-20.428-45.536-45.536c0-7.294,1.751-14.406,5.052-20.816h80.97     c3.301,6.41,5.052,13.522,5.052,20.816C301.539,418.869,281.111,439.297,256.002,439.297z M305.913,357.115h-99.815     c-3.262,0-5.915-2.653-5.915-5.915c0-3.261,2.654-5.914,5.915-5.914h99.815c3.262,0,5.914,2.653,5.914,5.914     C311.827,354.461,309.174,357.115,305.913,357.115z"/>
                                                                <path
                                                                    d="M305.913,194.748h-99.815c-11.991,0-21.745,9.754-21.745,21.745c0,9.411,6.021,17.422,14.404,20.443     c-2.708,7.012-4.121,14.495-4.121,22.12c0,33.837,27.529,61.366,61.366,61.366c33.838,0,61.367-27.529,61.367-61.366     c0-7.624-1.412-15.106-4.12-22.117c8.386-3.019,14.408-11.031,14.408-20.445C327.657,204.502,317.902,194.748,305.913,194.748z      M256.002,304.59c-25.109,0-45.536-20.428-45.536-45.536c0-7.294,1.751-14.406,5.052-20.816h80.97     c3.301,6.41,5.052,13.522,5.052,20.816C301.539,284.162,281.111,304.59,256.002,304.59z M305.913,222.407h-99.815     c-3.262,0-5.915-2.653-5.915-5.915c0-3.261,2.654-5.915,5.915-5.915h99.815c3.262,0,5.914,2.653,5.914,5.915     C311.827,219.753,309.174,222.407,305.913,222.407z"/>
                                                                <circle cx="276.089" cy="285.832" r="7.021"/>
                                                                <circle cx="271.027" cy="260.688" r="7.022"/>
                                                                <circle cx="288.498" cy="267.709" r="7.021"/>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="flex-grow-1 fs-16 fw-medium ms-3">
                                                <div className="fs-12 text-muted">City Mileage</div>
                                                <div className="fw-bold fs-15">{vin.city_mileage}</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-auto col-lg-3">

                                        <div className="d-flex align-items-center text-dark">
                                            <div className="flex-shrink-0">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                     fill="#000000"
                                                     height="800px" width="800px" version="1.1" id="Capa_1"
                                                     viewBox="0 0 353.926 353.926" style={{
                                                    margin: 'auto',
                                                    width: 'auto',
                                                    height: '30',
                                                    display: 'block'
                                                }}>
                                                    <path
                                                        d="M210.286,344.926c0,4.971-4.029,9-9,9h-48.65c-4.971,0-9-4.029-9-9s4.029-9,9-9h48.65  C206.257,335.926,210.286,339.955,210.286,344.926z M289.677,258.958v25.928c0,19.259-15.67,34.928-34.931,34.928H99.177  c-19.259,0-34.928-15.668-34.928-34.928v-25.928c0-4.971,4.029-9,9-9h2.394c-0.021-0.258-0.033-0.52-0.033-0.784v-24.118  c-0.013-0.535,0.023-1.066,0.105-1.588c0.204-1.329,0.699-2.561,1.418-3.631c0.705-1.055,1.639-1.969,2.767-2.659  c0.457-0.281,0.94-0.522,1.446-0.719c3.564-1.483,7.107-3.016,10.605-4.586V101.909c0-17.877,11.375-33.581,27.599-39.623  c-0.019-0.492-0.028-0.984-0.028-1.48V38.578C119.521,17.306,136.827,0,158.098,0h37.725C217.095,0,234.4,17.306,234.4,38.578  v22.229c0,0.495-0.01,0.988-0.028,1.478c6.395,2.378,12.129,6.28,16.702,11.351c0.16-0.3,0.318-0.599,0.478-0.899  c2.318-4.396,7.761-6.081,12.16-3.76c4.396,2.319,6.079,7.764,3.76,12.16c-16.845,31.926-41.307,61.508-72.707,87.923  c-25.063,21.083-53.512,39.294-84.813,54.313v26.586h134.02V141.64c0-4.971,4.029-9,9-9s9,4.029,9,9v108.318h18.706  C285.647,249.958,289.677,253.987,289.677,258.958z M137.521,60.807c0,1.842,0.243,3.629,0.699,5.33  c0.073,0.22,0.138,0.444,0.193,0.672c2.574,8.428,10.424,14.576,19.684,14.576h37.725c9.259,0,17.109-6.146,19.685-14.573  c0.057-0.231,0.122-0.458,0.195-0.68c0.455-1.699,0.698-3.484,0.698-5.325V38.578C216.4,27.231,207.169,18,195.822,18h-37.725  c-11.346,0-20.576,9.231-20.576,20.578V60.807z M109.951,203.272c56.184-28.521,102.335-68.15,131.162-112.739  c-2.612-4.871-6.75-8.658-11.666-10.83c-6.622,11.738-19.213,19.681-33.625,19.681h-37.725c-14.411,0-27.002-7.944-33.624-19.682  c-8.604,3.8-14.522,12.438-14.522,22.207V203.272z M271.677,267.958h-18.57c-0.046,0-0.091,0.001-0.136,0.001h-152.02  c-0.045,0-0.09,0-0.136-0.001H82.249v16.928c0,9.334,7.594,16.928,16.928,16.928h155.569c9.336,0,16.931-7.594,16.931-16.928  V267.958z"/>
                                                </svg>
                                            </div>
                                            <div className="flex-grow-1 fs-16 fw-medium ms-3">
                                                <div className="fs-12 text-muted">Standard Seating</div>
                                                <div className="fw-bold fs-15">{vin.standard_seating}</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-auto col-lg-3">

                                        <div className="d-flex align-items-center text-dark">
                                            <div className="flex-shrink-0">
                                                <i className="fa-solid fa-gauge fs-18"></i>
                                            </div>
                                            <div className="flex-grow-1 fs-16 fw-medium ms-3">
                                                <div className="fs-12 text-muted">Transmission</div>
                                                <div className="fw-bold fs-15">{lot.attributes.Transmission}</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-auto col-lg-3">

                                        <div className="d-flex align-items-center text-dark">
                                            <div className="flex-shrink-0">
                                                <i className="fa-solid fa-stopwatch-20 fs-18"></i>
                                            </div>
                                            <div className="flex-grow-1 fs-16 fw-medium ms-3">
                                                <div className="fs-12 text-muted">KM Driven</div>
                                                <div className="fw-bold fs-15">{lot.attributes.ODOValue} {lot.attributes.ODOUoM}</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-auto col-lg-3">

                                        <div className="d-flex align-items-center text-dark">
                                            <div className="flex-shrink-0">
                                                <i className="fa-solid fa-user-shield fs-18"></i>
                                            </div>
                                            <div className="flex-grow-1 fs-16 fw-medium ms-3">
                                                <div className="fs-12 text-muted">Ownership</div>
                                                <div className="fw-bold fs-15">2nd owner</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-auto col-lg-3">
                                        <div className="d-flex align-items-center text-dark">
                                            <div className="flex-shrink-0">
                                                <i className="fa-solid fa-gas-pump fs-18"></i>
                                            </div>
                                            <div className="flex-grow-1 fs-16 fw-medium ms-3">
                                                <div className="fs-12 text-muted">Fuel Type</div>
                                                <div className="fw-bold fs-15">{lot.attributes.FuelTypeCode}</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="mb-5 border-bottom pb-5">
                                <h4 className="fw-semibold fs-3 mb-4">Buy with <span
                                    className="font-caveat text-primary">Confidence</span></h4>
                                <div className="bg-no-repeat numbers-wrapper">
                                    <div className="row g-4">
                                        <div className="col-md-4">
                                            <div className="number-wrap text-center">
                                                <div
                                                    className="align-items-center bg-primary d-flex font-caveat fs-2 justify-content-center mb-4 mx-auto number-circle-sm rounded-circle text-white">1
                                                </div>
                                                <h6 className="fw-bold">ListOn Warranty</h6>
                                                <p className="fs-14 m-0 text-muted">Guaranteed coverage of all covered
                                                    parts during the period with zero cost</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="number-wrap text-center second">
                                                <div
                                                    className="align-items-center bg-primary d-flex font-caveat fs-2 justify-content-center mb-4 mx-auto number-circle-sm rounded-circle text-white">2
                                                </div>
                                                <h6 className="fw-bold">7-Day Return</h6>
                                                <p className="fs-14 m-0 text-muted">Love it, keep it. Changed your mind?
                                                    Return it within 7 days for a full refund</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="number-wrap text-center">
                                                <div
                                                    className="align-items-center bg-primary d-flex font-caveat fs-2 justify-content-center mb-4 mx-auto number-circle-sm rounded-circle text-white">3
                                                </div>
                                                <h6 className="fw-bold">Easy Financing Options</h6>
                                                <p className="fs-14 m-0 text-muted">Become eligible for Zero Down
                                                    Payment via EMIs of up to 72 months and instant loans</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-5 border-bottom pb-5">
                                <img src="https://themebull.net/html/liston/v2.4/assets/images/banner-02.jpg"
                                     className="img-fluid rounded-4" alt=""/>
                            </div>


                        </div>
                        <div className="col-lg-5 ps-xxl-5 sidebar">
                            <div className="card border-0 p-4 rounded-4">
                                <h4 className="fw-bold mb-1">{vin.Year} {vin.Make} {vin.Model}</h4>

                                <div className="d-flex flex-wrap gap-2 mt-3">
                                    <div className="bg-light fs-13 fw-bold px-3 py-1 rounded-2">36,594 KM</div>
                                    <div className="bg-light fs-13 fw-bold px-3 py-1 rounded-2">2ND OWNER</div>
                                    <div className="bg-light fs-13 fw-bold px-3 py-1 rounded-2">PETROL</div>
                                    <div className="bg-light fs-13 fw-bold px-3 py-1 rounded-2">DL-3C</div>
                                </div>
                                <ul className="d-flex flex-column fs-14 gap-2 list-unstyled mb-1 mt-4 text-muted">
                                    <li><i className="fa-solid fa-house-chimney fs-13 me-2"></i>Home Test Drive
                                        Available
                                    </li>
                                    <li><i className="fa-solid fa-location-dot fs-13 me-2"></i>1123 Fictional St, San
                                        Francisco
                                    </li>
                                    <li><i className="fa-solid fa-file-lines fs-13 me-2"></i>View Inspection Report</li>
                                    <li><i className="fa-solid fa-flag fs-13 me-2"></i> Get Service History Report</li>
                                </ul>
                                <div className="row g-0">
                                    <div className="col-sm-6 ps-0 py-3">
                                        <p className="fs-5 fw-bold fw-medium mb-0 text-primary">$7,957/month</p>
                                        <div className="fs-13 fw-medium text-capitalize">On Zero down payment</div>
                                        <Link to={'/'} className="fs-12 fw-medium text-blue text-uppercase">CHECK
                                            ELIGIBILITY &nbsp; →</Link>
                                    </div>
                                    <div className="col-sm-6 pe-0 py-3 text-end">
                                        <p className="fs-5 fw-bold fw-medium mb-0">$4.07 Lakh</p>
                                        <div
                                            className="fs-13 fw-medium text-decoration-line-through text-capitalize">$4.4
                                            Lakh
                                        </div>
                                        <Link to={'/'} className="fs-12 fw-medium text-blue text-uppercase">UNDERSTAND
                                            PRICE &nbsp; →</Link>
                                    </div>
                                </div>
                                <div className="d-flex gap-3 mt-3">
                                    <button type="button" className="btn btn-light" data-bs-toggle="tooltip"
                                            data-bs-placement="top" data-bs-title="favorite">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-heart" viewBox="0 0 16 16">
                                            <path
                                                d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15"/>
                                        </svg>
                                    </button>
                                    <button type="button" className="btn btn-primary w-100">AUCTION SEARCH
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <HomeFooter/>
        </div>
)
    ;
};

export default LotDetails;
