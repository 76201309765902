import React from 'react';
import {Link} from "react-router-dom";



// Компонент React с Owl Carousel
const HomeBlogCard = ( data ) => {
    const host = 'https://dev.copia.vin';

    const publishDateTime = new Date(data.publish_date);
    const currentTime = new Date();
    const timeDiff = currentTime - publishDateTime;

    let publishInfo;
    if (timeDiff < 60 * 60 * 1000) {
        // Если прошло менее часа, выводим "только что"
        publishInfo = "Right now";
    } else if (timeDiff < 24 * 60 * 60 * 1000) {
        // Если прошло менее 24 часов, выводим сколько часов назад
        const hoursAgo = Math.round(timeDiff / (60 * 60 * 1000));
        publishInfo = `${hoursAgo} h ago`;
    } else {
        // Если прошло более 24 часов, выводим дату публикации
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        publishInfo = publishDateTime.toLocaleDateString('en-GB', options);
    }
    return (
        <div className="bg-light blog-list-card d-md-flex mb-3 p-4 rounded-4">
            <div className="flex-grow-1 p-post me-md-5">

                <div className="hstack gap-3 mb-3">
                    <span className="fs-sm small text-muted">{publishInfo}</span>
                    <span className="opacity-25">|</span>
                    <Link to={'/'}  className="badge border fw-semibold text-primary bg-white" >{data.category}</Link>
                </div>

                <h2 className="fw-semibold news-title overflow-hidden text-capitalize"><Link to={`/news/${data.title.replace(/\s+/g, '-')}`} >{data.title}</Link></h2>

                <p className="mb-0">{data.short_content}</p>
            </div>
            <div className="flex-shrink-0 img-wrapper mt-4 mt-md-0">

                <Link to={'/'}
                      className="thumb position-relative d-block overflow-hidden position-relative rounded-4"><img
                    src={`${host}/${data.images}`} alt=""
                    height="300" width="300"
                    className="image-zoom-hover object-fit-cover "/></Link>

            </div>
        </div>




    );
}
export default HomeBlogCard;

