import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/HomeFooter.css';
const HomeFooter = () => {

    return (
        <footer className="footer-dark main-footer overflow-hidden position-relative pt-5">
            <div className="container pt-4">
                <div className="py-5">
                     <div className="bg-primary rounded-4">
                        <div className="col-xxl-10 col-md-11 col-10 d-flex flex-md-row flex-column-reverse align-items-md-end align-items-center mx-auto px-0 gap-4">
                            <img className="app-image flex-shrink-0" src="https://themebull.net/html/liston/v2.4/assets/images/phone-mpckup.png" width="270" alt="Mobile app"/>
                                <div className="align-items-lg-center align-self-center d-flex flex-column flex-lg-row ps-xxl-4 pt-5 py-md-3 text-center text-md-start">
                                    <div className="me-md-5">
                                        <h4 className="text-white">Download Our App</h4>
                                        <p className="mb-lg-0 text-white">It is a long established fact that a reader will be distracted by the readable content.</p>
                                    </div>
                                    <div className="d-flex flex-shrink-0 flex-wrap gap-3 justify-content-center">

                                        <Link to="/" className="align-items-center app-btn d-flex px-3 py-2 rounded-3 text-decoration-none text-white border"
                                           >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" version="1.1" width="40px" height="40px" viewBox="0 0 512 512"
                                                 >

<g id="7935ec95c421cee6d86eb22ecd114eed">

<path style={{display: 'inline'}}
      d="M248.644,123.476c-5.45-29.71,8.598-60.285,25.516-80.89   c18.645-22.735,50.642-40.17,77.986-42.086c4.619,31.149-8.093,61.498-24.826,82.965   C309.37,106.527,278.508,124.411,248.644,123.476z M409.034,231.131c8.461-23.606,25.223-44.845,51.227-59.175   c-26.278-32.792-63.173-51.83-97.99-51.83c-46.065,0-65.542,21.947-97.538,21.947c-32.96,0-57.965-21.947-97.866-21.947   c-39.127,0-80.776,23.848-107.19,64.577c-9.712,15.055-16.291,33.758-19.879,54.59c-9.956,58.439,4.916,134.557,49.279,202.144   c21.57,32.796,50.321,69.737,87.881,70.059c33.459,0.327,42.951-21.392,88.246-21.616c45.362-0.258,53.959,21.841,87.372,21.522   c37.571-0.317,67.906-41.199,89.476-73.991c15.359-23.532,21.167-35.418,33.11-62.023   C414.435,352.487,389.459,285.571,409.034,231.131z">

</path>

</g>

</svg>
                                            <div><span className="fs-13 d-block">Available  on the</span> <span
                                                className="fs-17 text-capitalize">App Store</span></div>
                                        </Link>
                                        <Link to="/" className="align-items-center app-btn d-flex fs-11 px-3 py-2 rounded-3 text-decoration-none text-white border"
                                          >
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                  fill="#ffffff" version="1.1"
                                                 width="40px" height="40px" viewBox="0 0 512 512"
                                                 enableBackground="new 0 0 512 512" >

<g id="b75708d097f2188dff6617b0f00f7c43">

<path display="inline"
      d="M120.606,169h270.788v220.663c0,13.109-10.628,23.737-23.721,23.737h-27.123v67.203   c0,17.066-13.612,30.897-30.415,30.897c-16.846,0-30.438-13.831-30.438-30.897v-67.203h-47.371v67.203   c0,17.066-13.639,30.897-30.441,30.897c-16.799,0-30.437-13.831-30.437-30.897v-67.203h-27.099   c-13.096,0-23.744-10.628-23.744-23.737V169z M67.541,167.199c-16.974,0-30.723,13.963-30.723,31.2v121.937   c0,17.217,13.749,31.204,30.723,31.204c16.977,0,30.723-13.987,30.723-31.204V198.399   C98.264,181.162,84.518,167.199,67.541,167.199z M391.395,146.764H120.606c3.342-38.578,28.367-71.776,64.392-90.998   l-25.746-37.804c-3.472-5.098-2.162-12.054,2.946-15.525c5.102-3.471,12.044-2.151,15.533,2.943l28.061,41.232   c15.558-5.38,32.446-8.469,50.208-8.469c17.783,0,34.672,3.089,50.229,8.476L334.29,5.395c3.446-5.108,10.41-6.428,15.512-2.957   c5.108,3.471,6.418,10.427,2.946,15.525l-25.725,37.804C363.047,74.977,388.055,108.175,391.395,146.764z M213.865,94.345   c0-8.273-6.699-14.983-14.969-14.983c-8.291,0-14.99,6.71-14.99,14.983c0,8.269,6.721,14.976,14.99,14.976   S213.865,102.614,213.865,94.345z M329.992,94.345c0-8.273-6.722-14.983-14.99-14.983c-8.291,0-14.97,6.71-14.97,14.983   c0,8.269,6.679,14.976,14.97,14.976C323.271,109.321,329.992,102.614,329.992,94.345z M444.48,167.156   c-16.956,0-30.744,13.984-30.744,31.222v121.98c0,17.238,13.788,31.226,30.744,31.226c16.978,0,30.701-13.987,30.701-31.226   v-121.98C475.182,181.14,461.458,167.156,444.48,167.156z">

</path>

</g>

</svg>
                                            <div><span className="fs-13 d-block">Get soon it on</span> <span
                                                className="fs-17 text-capitalize">Google Play</span></div>
                                        </Link>
                                    </div>
                                </div>
                        </div>
                     </div>

                </div>
                <div className="border-top py-5">
                    <div className="footer-row row gy-5 g-sm-5 gx-xxl-6">
                        <div className="border-end col-lg-4 col-md-7 col-sm-6">
                            <h5 className="fw-bold mb-4">Get In Touch</h5>
                            <div className="mb-4">Join our newsletter and receive the best job<br
                                className="d-none d-xxl-block"/> openings of the week, right on your inbox.
                            </div>

                            <h5 className="fw-bold mb-4">Want to join ListOn?<br/> Write us !</h5>
                            <span>support@copia.vin</span>
                        </div>
                        <div className="border-end col-lg-4 col-md-5 col-sm-6">
                            <h5 className="fw-bold mb-4">Stay Connect</h5>
                            <div>1123 Fictional St, San Francisco<br className="d-none d-xxl-block"/> , CA 94103</div>
                            <div className="mt-4">
                                <Link to="/" className="d-block fw-medium mb-1" >
                                    <i className="fa-solid fa-phone me-2"></i><span>(123) 456-7890</span>
                                </Link>
                                <Link to="/" className="email-link d-block fw-medium" >
                                    <i className="fa-solid fa-envelope me-2"></i>
                                    support@ListOn.com
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <h5 className="fw-bold mb-4">Get In Touch</h5>
                            <div className="newsletter position-relative mt-4">
                                <input type="email" className="form-control" placeholder="name@example.com"/>
                                <button type="button"
                                        className="btn btn-primary search-btn position-absolute top-50 rounded-circle">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px"
                                         viewBox="0 0 24 24" fill="none">
                                        <path
                                            d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z"
                                            fill="#ffffff"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="border-top my-4"></div>
                            <h5 className="fw-bold mb-4">Follow the location</h5>

                            <ul className="d-flex flex-wrap gap-2 list-unstyled mb-0 social-icon">
                                <li>
                                    <Link to="/"
                                          className="rounded-circle align-items-center d-flex fs-19 icon-wrap justify-content-center rounded-2 text-white inst">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" width="25px"
                                             height="25px" viewBox="0 0 24 24" role="img">
                                            <path
                                                d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913a5.885 5.885 0 0 0 1.384 2.126A5.868 5.868 0 0 0 4.14 23.37c.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558a5.898 5.898 0 0 0 2.126-1.384 5.86 5.86 0 0 0 1.384-2.126c.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913a5.89 5.89 0 0 0-1.384-2.126A5.847 5.847 0 0 0 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227a3.81 3.81 0 0 1-.899 1.382 3.744 3.744 0 0 1-1.38.896c-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421a3.716 3.716 0 0 1-1.379-.899 3.644 3.644 0 0 1-.9-1.38c-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678a6.162 6.162 0 1 0 0 12.324 6.162 6.162 0 1 0 0-12.324zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405a1.441 1.441 0 0 1-2.88 0 1.44 1.44 0 0 1 2.88 0z"/>
                                        </svg>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/"
                                          className="rounded-circle align-items-center d-flex fs-19 icon-wrap justify-content-center rounded-2 text-white fb">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" width="25px"
                                             height="25px" viewBox="0 0 1920 1920">
                                            <path
                                                d="m1416.013 791.915-30.91 225.617h-371.252v789.66H788.234v-789.66H449.808V791.915h338.426V585.137c0-286.871 176.207-472.329 449.09-472.329 116.87 0 189.744 6.205 231.822 11.845l-3.272 213.66-173.5.338c-4.737-.451-117.771-9.25-199.332 65.655-52.568 48.169-79.191 117.433-79.191 205.65v181.96h402.162Zm-247.276-304.018c44.446-41.401 113.71-36.889 118.787-36.663l289.467-.113 6.204-417.504-43.544-10.717C1511.675 16.02 1426.053 0 1237.324 0 901.268 0 675.425 235.206 675.425 585.137v93.97H337v451.234h338.425V1920h451.234v-789.66h356.7l61.932-451.233H1126.66v-69.152c0-54.937 14.214-96 42.078-122.058Z"
                                                fill-rule="evenodd"/>
                                        </svg>
                                    </Link>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
            <div className="container border-top">
                <div className="align-items-center g-3 py-4 row">
                    <div className="col-lg-auto">

                        <ul className="list-unstyled list-separator mb-2 footer-nav">
                            <li className="list-inline-item"><Link to="/terms-of-use">Terms of use</Link></li>
                            <li className="list-inline-item"><Link to="/privacy-policy">Privacy policy</Link></li>
                            <li className="list-inline-item"><Link to="/public-agreement">Public Agreement</Link></li>
                        </ul>

                    </div>
                    <div className="col-lg order-md-first">
                        <div className="align-items-center row">
                            <Link to="/" className="col-sm-auto footer-logo mb-2 mb-sm-0">
                                <img src="https://svgshare.com/i/14pZ.svg" alt=""/>
                            </Link>

                            <div className="col-sm-auto copy">© 2024 Copia.vin - All Rights Reserved- All Rights
                                Reserved
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default HomeFooter;
