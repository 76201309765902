import React, { useState, useEffect } from 'react';
import LotCard from './LotCard'; // Подключаем компонент LotCard
import axios from 'axios';
import {Link} from "react-router-dom";

const SearchAutoLotList = ({ filters }) => {
    const [lots, setLots] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const lotsPerPage = 10;

    useEffect(() => {
        const fetchLots = async () => {
            try {
                let url = 'https://dev.copia.vin/filterlots?';

                if (filters.make) {
                    url += `Make=${filters.make}&`;
                }
                if (filters.model) {
                    url += `Model=${filters.model}&`;
                }
                if (filters.auction) {
                    url += `AuctionName=${filters.auction}&`;
                }

                url = url.slice(0, -1);

                const response = await axios.get(url);
                const sortedLots = response.data.sort((a, b) => b.lotId - a.lotId); // Сортировка лотов с последнего по первый по ID лота
                setLots(sortedLots);
                setLots(response.data);
            } catch (error) {
                console.error('Error fetching lots:', error);
            }
        };

        fetchLots();
    }, [filters]);

    const indexOfLastLot = currentPage * lotsPerPage;
    const indexOfFirstLot = indexOfLastLot - lotsPerPage;
    const currentLots = lots.slice(indexOfFirstLot, indexOfLastLot);

    const paginate = pageNumber => setCurrentPage(pageNumber);
    const nextPage = () => {
        if (currentPage < Math.ceil(lots.length / lotsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className="col-xl-9 ps-lg-4 ps-xl-5 sidebar">
            <div className="theiaStickySidebar">
                <div style={{textAlign: 'left'}} className="d-flex flex-wrap align-items-center mb-3 gap-2">
                    <div className="col fs-18 text-nowrap">All <span className="fw-bold text-dark">{lots.length}</span> auction
                        found
                    </div>
                </div>
                <div className="row g-4 mb-5">
                    {currentLots.map((lot) => (
                        <LotCard key={lot.id} lot={lot}/>
                    ))}
                </div>

                <nav className="justify-content-center mt-5 pagination align-items-center">
                    <Link className={`prev page-numbers page-item ${currentPage === 1 ? 'disabled' : ''}`} onClick={prevPage}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                             fill="currentColor" className="bi bi-arrow-left-short" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                  d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
                        </svg>
                        previous
                    </Link>
                    {Array.from({length: Math.ceil(lots.length / lotsPerPage)}, (_, i) => (
                        <span onClick={() => paginate(i + 1)}  key={i} className={`page-numbers ${currentPage === i + 1 ? 'current' : ''}`}>
                                {i + 1}

                        </span>
                    ))}

                    <Link onClick={nextPage} className={`next page-numbers page-item ${currentPage === Math.ceil(lots.length / lotsPerPage) ? 'disabled' : ''}`} >
                        next
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                             fill="currentColor" className="bi bi-arrow-right-short"
                             viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                  d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"></path>
                        </svg>
                    </Link>
                </nav>

            </div>
        </div>
    );
};

export default SearchAutoLotList;
