import React from 'react';
import { Link } from 'react-router-dom';
import {HeaderNotHome, HomeFooter} from "./";


const NotFoundPage = () => {
    return (
        <div>
            <HeaderNotHome/>
            <div className="py-5 bg-light mx-3 mb-3 rounded-4">
                <div className="container py-4">
                    <div className="row justify-content-center">
                        <div className="col-sm-10 col-md-9 col-lg-7 text-center">
                            <h2 className="error-text font-caveat text-primary">404</h2>

                            <div className="">
                                <h2 className="fw-semibold mb-3 text-capitalize">Page not pound.</h2>
                                <p className="fs-16">The server encountered something unexpected that didn't allow it to
                                    complete the request. We apologize. You can go back to main page:</p>
                                <Link className="btn btn-primary btn-lg mt-3" to="/">
                                    <span>Go To Home Page</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <HomeFooter/>
        </div>

    );
};

export default NotFoundPage;