import React from 'react';
import '../styles/HeaderNotHome.css';
import {HeaderNotHome, HomeFooter, PageHeader} from "./index";

const PublicAgreement = () => {
    return (
        <div>
            <HeaderNotHome/>
            <PageHeader title="Public <span className='font-caveat text-white'>agreement</span>"/>
            <div className="py-5" style={{textAlign: 'left'}}>
                <div className="container py-4">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                            <div className="cs-content">
                                <h1 className="bd-title">Public agreement</h1>
                                <h1>Public agreement</h1>
                                <p>This offer is aimed at an unlimited number of persons and is a public offer to
                                    conclude a Public Service Agreement, posted on the Internet at
                                    <a href="https://copia.vin">copia.vin</a> (hereinafter referred to as the Public Offer
                                    or Agreement) on the following conditions:
                                </p>
                                <p>By making the Acceptance, the Customer confirms:</p>
                                <ul>
                                    <li>that all his actions are aimed at the real onset of legal consequences that are
                                        stipulated by the Agreement;
                                    </li>
                                    <li>that the Customer is not limited in the right to make transactions;</li>
                                    <li>that the Customer is not recognized as legally incompetent (in whole or in
                                        part), does not remain in a painful state, does not suffer from diseases at the
                                        time of the conclusion of this Agreement, is not under the influence of drugs
                                        and other drugs that impede the understanding of the essence of the Agreement
                                        and / or its action or inaction.
                                    </li>
                                </ul>
                                <ol className="recursive">
                                    <li>TERMS USED IN THE AGREEMENT
                                        <ol>
                                            <li>Acceptance is full and unconditional acceptance of the offer by the
                                                Customer by taking actions aimed at confirming the Public offer using
                                                the technical capabilities of the Site.
                                            </li>
                                            <li>Customer is a person who has accepted the terms of this Agreement and
                                                becomes the Customer of the Contractor's services under the concluded
                                                Agreement. The customer can be any competent individual who has reached
                                                18 years of age and who intends to receive the services of the
                                                Contractor in the manner and on the conditions determined by this
                                                Agreement.
                                            </li>
                                            <li>Contractor (Organizer) is the site's service provider.</li>
                                            <li>Site Administration is a person or group of persons who (s) has the
                                                authority to manage the Site, moderate it, post content on the Site and
                                                interact with Users, as well as perform other actions related to the use
                                                of the Site. The Site Administration acts on behalf of the Site Owner.
                                            </li>
                                            <li>Website - a set of data, electronic (digital) information, other objects
                                                of copyright and (or) related rights, etc., interconnected and
                                                structured within the address
                                                <a href="https://copia.vin">copia.vin</a></li>
                                            <li>Offer means this offer from the Contractor to conclude a Public
                                                Agreement for the provision of Services, which is posted on the Site,
                                                aimed at an indefinite number of persons.
                                            </li>
                                            <li>User means an individual or legal entity that uses the Site for
                                                information purposes on an unpaid or paid basis.
                                            </li>
                                            <li>Registration in your personal account means a procedure voluntarily
                                                performed by the User on the Website, which consists in performing a set
                                                of actions by the User (according to a special form), including the
                                                provision of personal data in order to create a Personal Account and
                                                access to the Website services.
                                            </li>
                                            <li>Personal Account is used to define a closed part of the Site, accessible
                                                to the User after registration, designed to manage and configure
                                                Autoastat services, to store and edit User data. The personal account
                                                contains information that the User reported about himself when
                                                registering on the Site and allows Autoastat to identify the User using
                                                an individual login and password.
                                            </li>
                                            <li>Login is used to define the unique name
                                                (identifier) &ZeroWidthSpace;&ZeroWidthSpace;of the User on the Site,
                                                used to enter the User into the Personal Account and perform any actions
                                                using the functionality of the Site, including the conclusion and
                                                implementation of agreements, but not limited to this.
                                            </li>
                                            <li>Password is a secret character set that is required to enter the User’s
                                                Personal Account, known only to the User.
                                            </li>
                                            <li>Report is used to define detailed information about the vehicles that
                                                were available at auction portals, if the vehicle was put up for
                                                auction.
                                            </li>
                                            <li>Lot is used to define a vehicle in respect of which the auction was
                                                conducted by bidders (name, quantitative and qualitative
                                                characteristics, cost, etc.).
                                            </li>
                                        </ol>
                                    </li>
                                    <li>BASIC PROVISIONS
                                        <ol>
                                            <li>The agreement is concluded between the Contractor and the Customer of
                                                services in the form of an accession agreement.
                                            </li>
                                            <li>This Agreement is a public agreement, which is considered to be
                                                concluded between the Contractor, on the one hand, the Customer, on the
                                                other, from the moment of Acceptance, the last of all, without
                                                exception, the conditions and provisions of this Agreement.
                                            </li>
                                            <li>In the manner and under the conditions defined by this Agreement, the
                                                Contractor undertakes to provide the Services to the Customer, and the
                                                Customer undertakes to accept and pay for the Services provided, in case
                                                the Services are provided on a paid basis.
                                            </li>
                                            <li>The actions indicating consent to comply with the terms of this
                                                Agreement are the acceptance by the Customer of the terms of this
                                                Agreement.
                                            </li>
                                            <li>The Customer carries out the Acceptance of the Agreement after reviewing
                                                its terms and conditions posted on the Site by aggregate or separate
                                                execution of any of the following actions:
                                                <ol>
                                                    <li>Putting down the appropriate mark "I have read (read) the terms
                                                        of this Agreement and accept its terms."
                                                    </li>
                                                    <li>Payment by the Customer of the Service in full, if it is
                                                        provided on a paid basis.
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>SUBJECT OF THE AGREEMENT
                                        <ol>
                                            <li>The Contractor provides the Customer with information-historical and
                                                statistical data on the sale of cars and other vehicles at leading
                                                auction portals.
                                            </li>
                                            <li>The site user has the opportunity to search for data on a specific lot
                                                or VIN number, as well as search for the selected vehicle parameters
                                                (type, model, manufacturer, year of release, auction, auction date,
                                                auction location).
                                            </li>
                                            <li>Services, under the terms of this Agreement, are the provision of
                                                information services, namely the provision of access to the Site and the
                                                use of its functionality for personal non-commercial use and cannot be
                                                interpreted as providing advice and the only source of information when
                                                buying a used vehicle (hereinafter - the Service).
                                            </li>
                                            <li>Information on the Site is provided on an “as is” basis. The Contractor
                                                does not guarantee that the Services will meet the goals and
                                                expectations of the Users or any other persons.
                                            </li>
                                            <li>The information published on the Site consists of information posted on
                                                auction portals and other open resources and may include technical,
                                                typographical or photographic errors. The Contractor makes no warranties
                                                that any publication is accurate, complete or current.
                                            </li>
                                            <li>The Contractor may update the materials on the Site at any time without
                                                warning and is not responsible for updating resources.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>COST OF SERVICES AND RATES
                                        <ol>
                                            <li>The cost of the Services depends on the volume and nature of the
                                                Services that are provided, and is indicated immediately after the
                                                election of a particular Service.
                                            </li>
                                            <li>The Contractor has the right to unilaterally change the cost of the
                                                Services until they are paid.
                                            </li>
                                            <li>Payment for Services by the Customer confirms familiarization and full
                                                acceptance of the terms of this Agreement.
                                            </li>
                                            <li>Payment for the Services is made by bank transfer to the account of the
                                                Contractor, the details that are provided to him after choosing the
                                                Service.
                                            </li>
                                            <li>The service is considered paid from the moment the funds are credited to
                                                the Contractor’s current account.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>PROCEDURE FOR PROVISION OF SERVICES
                                        <ol>
                                            <li>For efficient and full use of the Site, the User needs to register by
                                                correctly and fully filling out all the necessary fields of the
                                                registration form, entering the following data: name, surname, email and
                                                phone number of the User.
                                                <ol>
                                                    <li>When registering, the User is obliged to provide reliable and
                                                        relevant information, including a unique login for each User,
                                                        email address, password for access to the Personal Account. The
                                                        Contractor reserves the right to change the fields of the
                                                        registration form and require the User to enter additional
                                                        information.
                                                    </li>
                                                    <li>The User agrees and undertakes to provide true, accurate and
                                                        complete information for his Personal Account and to maintain
                                                        its truthfulness, accuracy and completeness.
                                                    </li>
                                                    <li>By registering on the Site, the User has access to the Personal
                                                        Account using the entered login and password, which allows him
                                                        to organize the search results (bookmarks, search history,
                                                        acquired Reports).
                                                    </li>
                                                    <li>After registration, the username and password chosen by the User
                                                        are sufficient information for the User to access the Service.
                                                        The user does not have the right to transfer his username and
                                                        password to third parties, bears full responsibility for their
                                                        safety, independently choosing the method of their storage. Any
                                                        actions committed using his username and password are considered
                                                        committed by the corresponding User.
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>The procedure for the provision of free services by the Contractor:
                                                <ol>
                                                    <li>The user, without making payments according to the selected
                                                        parameters, gets limited access to the full information posted
                                                        on the website: photographs of the vehicle, limited information
                                                        regarding the vehicle, traffic accidents, information about the
                                                        last auction held.
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>The procedure for the provision of services by the Contractor on a paid
                                                basis:
                                                <ol>
                                                    <li>The customer, on a paid basis, gets access to the full
                                                        information posted on the website, in particular, but not
                                                        exclusively: the final price of the vehicle, data on the VIN
                                                        number, data regarding the seller, vehicle mileage, vehicle
                                                        sales history, access to high-quality photographs from auctions.
                                                    </li>
                                                    <li>The customer can receive a Report with information on the Lot on
                                                        a paid basis. The reports provide information about vehicles
                                                        from open Internet resources, auction portals and may contain
                                                        photos of the vehicle, as well as detailed information about
                                                        vehicles that were available to the audience on auction portals,
                                                        if the vehicle was put up for auction.
                                                    </li>
                                                    <li>The report in particular, but not exclusively, includes
                                                        information about the characteristics of the vehicle,
                                                        information about known damage, the history of the sale of the
                                                        vehicle at auctions, photographs, etc.
                                                    </li>
                                                    <li>The customer can pay both for the Report for one Lot and for a
                                                        certain selected number of Reports.
                                                    </li>
                                                    <li>When paying for the Report, the Customer receives information on
                                                        the Lot, which was on Autoastat at this VIN number at the time
                                                        of the acquisition of the Report. If after purchasing the
                                                        Report, new, additional information on the Lot appears, the
                                                        information will not be part of the acquired Report and requires
                                                        additional payment.
                                                    </li>
                                                    <li>The Contractor does not guarantee that the Reports will be
                                                        available for any vehicle, and also that the information from
                                                        the Report is accurate, comprehensive, verified and complete.
                                                    </li>
                                                    <li>The Contractor is not responsible for the accuracy or
                                                        completeness of the information provided in the Report. The
                                                        Contractor does not provide a guarantee that the vehicle was not
                                                        put up for auction at an auto auction if the corresponding entry
                                                        is not in the Report.
                                                    </li>
                                                    <li>The Customer gets access to the acquired Report in the Personal
                                                        Account, as well as an additional email with a personal link to
                                                        the Report is sent to the specified email address of the
                                                        Customer.
                                                    </li>
                                                    <li>The Customer, when paying for the Services, namely: access to
                                                        the service for a certain period of time, receives up-to-date
                                                        information on the Lots during the entire paid period.
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>RIGHTS AND OBLIGATIONS OF THE PARTIES
                                        <ol>
                                            <li>Rights and obligations of the Customer
                                                <ol>
                                                    <li>The customer has the right to receive the Services under the
                                                        terms of this Agreement.
                                                    </li>
                                                    <li>The Customer is entitled to receive information from the
                                                        Contractor about the Services that are provided and the
                                                        conditions for their provision.
                                                    </li>
                                                    <li>The customer is entitled to enter the Personal Account under his
                                                        account at the same time from only one access point (personal
                                                        computer, laptop, netbook, tablet, etc.).
                                                    </li>
                                                    <li>The customer is obliged to provide the Contractor with complete
                                                        and reliable information about his data. In the event of a
                                                        change in data, the Customer is obligated to update relevant
                                                        information in the Personal Account, taking on the risks of the
                                                        consequences of untimely commission of such actions.
                                                    </li>
                                                    <li>The customer is obliged to ensure the security and
                                                        confidentiality of the information specified and used in the
                                                        Personal Account. The customer independently ensures the safety
                                                        of his personal login and password. The Customer bears full
                                                        responsibility for any actions carried out through his Personal
                                                        Account, and agrees to immediately inform the Site
                                                        Administration of any case of unauthorized use of the Personal
                                                        Account, any violation of the established safety standards, as
                                                        well as a violation of the terms of this Agreement.
                                                    </li>
                                                    <li>The Customer is obligated not to reproduce, repeat, copy, sell,
                                                        or use for any purpose the information and materials that became
                                                        available to him in connection with the provision of the
                                                        Services, except for their personal use.
                                                    </li>
                                                    <li>Customer agrees not to post or upload content or elements to
                                                        inappropriate categories and services on the Site.
                                                    </li>
                                                    <li>Customer is prohibited from distributing or posting spam,
                                                        unwanted or mass emails, spreading viruses, or performing any
                                                        other actions that could harm Autoastat.
                                                    </li>
                                                    <li>The Customer agrees and confirms that using the Services, in any
                                                        case, it does not contribute to fraud, deception or breach of
                                                        trust; does not carry out transactions with stolen or
                                                        counterfeit items and / or items whose free circulation is
                                                        limited and / or prohibited in accordance with applicable law;
                                                        does not affect the ownership of the Contractor or third
                                                        parties, including intellectual property; Does not publish
                                                        information that offends someone's honor, dignity or business
                                                        reputation; does not carry out slander, does not threaten; Does
                                                        not call for the commission of a crime, and also does not incite
                                                        ethnic and ethnic hatred; not conducive to terrorist and
                                                        extremist activities.
                                                    </li>
                                                    <li>The Customer has the right to require the Contractor to provide
                                                        the Services in accordance with the terms of this Agreement.
                                                    </li>
                                                    <li>The customer has other rights provided by the legislation of
                                                        Ukraine and international law.
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>Rights and obligations of the Contractor:
                                                <ol>
                                                    <li>The Contractor is obliged to organize and provide the Customer
                                                        with the proper provision of the Services in accordance with
                                                        this Agreement.
                                                    </li>
                                                    <li>The Contractor is obliged to ensure the storage and processing
                                                        of the Customer’s personal data, to provide confidentiality of
                                                        these data in the process of their processing and to use them
                                                        exclusively for the high-quality provision of Services.
                                                    </li>
                                                    <li>The Contractor is obliged to provide the Customer with
                                                        information on the provision of the Services upon his request.
                                                    </li>
                                                    <li>The Contractor has the right to receive from the Customer the
                                                        information necessary to fulfill its obligations under the
                                                        Agreement.
                                                    </li>
                                                    <li>In the absence of a specific Lot on the Site, the Contractor has
                                                        the right, upon the User’s request, to post it on the Site after
                                                        the User confirms the authenticity of the Lot and the data
                                                        confirming its purchase provided to
                                                        <a href="mailto:info@copia.vin">info@copia.vin</a>.
                                                    </li>
                                                    <li>The Contractor has the right to close or suspend the operation
                                                        of the Site or any part thereof, to supplement, modify, update
                                                        materials without prior notice to the Customer.
                                                    </li>
                                                    <li>The Contractor has the right to unilaterally determine the cost
                                                        of providing the Services.
                                                    </li>
                                                    <li>The Contractor has the right to conduct surveys, survey the
                                                        Customer, including request feedback on the received Services,
                                                        publish or otherwise distribute the results of the survey
                                                        (survey), reviews on the provided Services.
                                                    </li>
                                                    <li>The Contractor has the right to unilaterally amend the terms of
                                                        the Agreement by posting a new version of the Agreement on the
                                                        Site.
                                                    </li>
                                                    <li>If the Customer has contacted the Contractor with an official
                                                        letter to the address
                                                        <a href="mailto:info@copia.vin">info@copia.vin</a>, about deleting
                                                        or changing information on a particular Lot, as such, that
                                                        contains false, inaccurate or incomplete data, the Contractor
                                                        must consider it and take all necessary measures.
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>RESPONSIBILITY OF THE PARTIES AND DISPUTE RESOLUTION PROCEDURE
                                        <ol>
                                            <li>In the event of failure to fulfill or improper performance of their
                                                obligations under this Agreement, the Parties shall be liable in
                                                accordance with the current legislation of Ukraine, the terms of this
                                                Agreement, as well as applicable international law.
                                            </li>
                                            <li>The Contractor is not responsible for the inability to use the Services
                                                for reasons dependent on the Customer.
                                            </li>
                                            <li>The parties, taking into account the nature of the Services provided,
                                                undertake, in case of disputes and disagreements related to the
                                                provision of the Services, without fail to apply the pre-trial procedure
                                                for the settlement of the dispute, in particular by sending the
                                                corresponding written claim. If it is impossible to resolve the dispute
                                                in the pretrial order, such disputes are resolved in court in accordance
                                                with the rules of jurisdiction and jurisdiction established by the
                                                legislation of Ukraine and international law.
                                            </li>
                                            <li>The Contractor is not responsible for the failure by the Customer to
                                                receive the Services under this Agreement if the Customer provides false
                                                information in fulfillment of the terms of the Agreement, including, but
                                                not exclusively, contact information (e-mail, other means of
                                                communication), as well as failure to provide information in case of her
                                                changes.
                                            </li>
                                            <li>The Contractor is not responsible for the non-receipt by the Customer of
                                                the Services under this Agreement if the Customer cannot receive the
                                                Services for technical reasons beyond the control of the Contractor, in
                                                particular, if the Customer does not have access to the Internet as a
                                                result of the actions of communication operators, providers, influence
                                                computer viruses and / or other malicious programs, due to the lack of
                                                necessary software and hardware and / or inadequate payment for such
                                                access.
                                            </li>
                                            <li>The Contractor is not responsible for the actions or omissions of the
                                                Customer, as a result of which damage was caused to other Customers of
                                                the Contractor's services, and is also not responsible for damage caused
                                                to the Customer as a result of actions or inaction of other Customers of
                                                the Services.
                                            </li>
                                            <li>The Contractor is not responsible for the Lot’s non-compliance with the
                                                Customer’s expectations and / or its subjective assessment. Such a
                                                discrepancy with expectations and / or a negative subjective assessment
                                                is not a reason to consider the Services provided poorly, or not to an
                                                agreed extent.
                                            </li>
                                            <li>The Contractor is not responsible for the content of third-party
                                                information posted on the Site and third-party websites to which we
                                                link. The Contractor is not responsible for the data collected by third
                                                parties, namely: information regarding the condition, safety,
                                                marketability, history of emergencies, sales history and registration
                                                data of vehicles.
                                            </li>
                                            <li>The Contractor is not responsible for non-receipt of profit (income),
                                                profit below expectations of the Customer, as well as for direct and
                                                indirect losses of the Customer.
                                            </li>
                                            <li>The Customer is solely responsible for any consequences of its actions
                                                or omissions, including the expected results from the received Service.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>PERSONAL DATA
                                        <ol>
                                            <li>By concluding this Agreement, the Customer confirms that he is fully
                                                acquainted with and agrees with its terms, as well as with the
                                                provisions of the Law of Ukraine “On the Protection of Personal Data”,
                                                the Privacy Policy published on this Site, and also gives permission to
                                                the Customer to process his personal data.
                                            </li>
                                            <li>The personal data provided by the Customer is personal and confidential
                                                information, according to the Privacy Policy published on the <a
                                                    href="https://copia.vin/privacy-policy">Privacy Policy</a> page and
                                                which is an integral part of the Agreement.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>FORCE MAJEURE
                                        <ol>
                                            <li>The Parties are exempted from liability for partial or full failure to
                                                fulfill obligations under this Agreement if this failure was the result
                                                of force majeure circumstances (force majeure circumstances) arising
                                                after the conclusion of this Agreement and made it impossible to fulfill
                                                obligations in accordance with the terms of this Agreement. Such
                                                circumstances, in particular, but not limited to, include emergencies of
                                                a man-made, natural or environmental nature, accidents in power supply
                                                systems, the destruction of these systems caused, in particular, by
                                                earthquakes, floods, hurricanes, etc., a prolonged lack of electricity
                                                and the Internet for reasons beyond the control of the parties,
                                                hostilities, a strike, riots, and other unlawful acts, as well as
                                                floods, fires, anti-terrorist operations, earthquakes and other natural
                                                disasters actions, war, military operations, uncontrolled, unlawful acts
                                                and acts of vandalism of third parties, revolutionary actions, public
                                                unrest, acts or actions of government bodies, adoption of legal or
                                                by-laws that directly affect the parties' ability to fulfill the terms
                                                of this Agreement, and any other extraordinary circumstances.
                                            </li>
                                            <li>If force majeure circumstances and their consequences continue to
                                                operate for more than three months, the Parties should negotiate as soon
                                                as possible in order to identify alternative methods acceptable for both
                                                Parties to fulfill the terms of this Agreement and to achieve the
                                                corresponding written agreements.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>DURATION OF THE AGREEMENT AND THE TERMS OF ITS TERMINATION
                                        <ol>
                                            <li>This Agreement shall enter into force upon acceptance of this Agreement
                                                by the Customer and is valid until the Parties fully fulfill their
                                                obligations.
                                            </li>
                                            <li>This Agreement may be terminated ahead of schedule:
                                                <ul>
                                                    <li>by mutual agreement of the Parties;</li>
                                                    <li>at the initiative of the Customer, in case of violation by the
                                                        Contractor of the terms of this Agreement;
                                                    </li>
                                                    <li>at the request of the Contractor, in case of violation by the
                                                        Customer of the terms of this Agreement.
                                                    </li>
                                                </ul>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>FINAL PROVISIONS
                                        <ol>
                                            <li>Acceptance by the Customer of the terms of this Agreement (acceptance of
                                                the offer) is possible only in full. Partial acceptance of the offer, as
                                                well as the acceptance of the offer on other conditions are not allowed.
                                            </li>
                                            <li>The customer guarantees that all the conditions of the Agreement are
                                                clear to him and he accepts them unconditionally and in full, without
                                                any reservations and conditions.
                                            </li>
                                            <li>The Customer gives his consent to inform about the other Services of the
                                                Contractor and (or) its partners by e-mailing to the address indicated
                                                by the Customer. This consent is given for an indefinite period and is
                                                valid until the Contractor receives a notification from the Customer
                                                about the refusal to send it to the email address
                                                <a href="mailto:info@copia.vin">info@copia.vin</a>.
                                            </li>
                                            <li>Cases not regulated by this Agreement, the Parties undertake to resolve,
                                                being guided by the norms established by the current legislation of
                                                Ukraine and international standards.
                                            </li>
                                            <li>The parties give each other their consent to the processing and storage
                                                of personal data that they become aware of in connection with the
                                                conclusion of this Agreement, to the extent that it is necessary in
                                                accordance with the requirements of applicable law.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <HomeFooter/>
        </div>
    );
}

export default PublicAgreement;
